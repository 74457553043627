/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { batch, signal } from '@preact/signals';

/**
 * GoCanvasUtil
 * @class
 * @export
 */
export class GoCanvasUtil {
  public static SELECTORS = {
    NODE_CONNECTOR: 'dpa-canvas-node-connector',
  };

  public static CONSTANTS = {
    DRAG_HANDLE: 'drag-handle',
    NEW_STEP_TYPE: 'new-step-type',
  };

  /**
   * getClosestCanvasNodeByUiId
   * @static
   * @param {HTMLElement} canvasRef
   * @param {string} uiId
   * @returns {HTMLElement}
   * @memberOf GoCanvasUtil
   */
  public static getClosestCanvasNodeByUiId(canvasRef: HTMLElement, uiId: string): HTMLElement {
    const node = canvasRef.querySelector(`[data-node-uiid=${uiId}]`);
    if (!node) {
      return;
    }
    return node.closest('.canvas-node');
  }
}

const nodeInfoByUiIdMap = new Map<string, GenericObject>();

export const setNodeInfoByUiId = (uiId: string, nodeInfo: GenericObject) => {
  nodeInfoByUiIdMap.set(uiId, nodeInfo);
};

export const getNodeInfoByUiId = (uiId: string): GenericObject => {
  return nodeInfoByUiIdMap.get(uiId) ?? {};
};

export const removeNodeInfoByUiId = (uiId: string) => {
  nodeInfoByUiIdMap.delete(uiId);
};

export const selectedNodeUiId = signal('');
export const selectedNodeInfo = signal({});

export const setSelectedNodeId = (uiId: string) => {
  batch(() => {
    selectedNodeUiId.value = uiId;
    selectedNodeInfo.value = uiId ? getNodeInfoByUiId(uiId) : {};
  });
};

export const resetSelectedNode = () => {
  setSelectedNodeId(undefined);
};
