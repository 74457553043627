/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';

/**
 * AutomationPreview Model Object
 *
 * @export
 * @class AutomationPreview
 */
@Serializable
export class AutomationPreview {
  @JsonProperty('friendly_name')
  public friendlyName: string = undefined;

  @JsonProperty({ name: 'last_seen', customConverter: dateFormatConverter })
  public lastSeen: number = undefined;

  @JsonProperty('device_location_group_name')
  public deviceLocationGroupName: string = undefined;

  @JsonProperty('compliance_status')
  public complianceStatus: string = undefined;
}
