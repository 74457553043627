/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * Entity
 * All available entities
 *
 * @export
 * @class Entity
 */
export class Entity {
  // Workspace One UEM
  public static readonly APP_FEEDBACK: string = 'appfeedback';
  public static readonly APPLICATION: string = 'application';
  public static readonly APPLICATION_CHANGE_EVENT: string = 'applicationchangeevent';
  public static readonly DEVICE: string = 'device';
  public static readonly DEVICE_CUSTOM_ATTRIBUTES: string = 'devicecustomattributes';
  public static readonly DEVICE_CHANGE_EVENT: string = 'devicechangeevent';
  public static readonly DEVICE_RISK_SCORE: string = 'deviceriskscore';
  public static readonly DEVICE_SUMMARY_RISK_SCORE: string = 'devicesummaryriskscore';
  public static readonly DEVICESENSORS: string = 'devicesensors';
  public static readonly IOSUPDATE: string = 'iosupdate';
  public static readonly MACOSUPDATE: string = 'macosupdate';
  public static readonly PRODUCT: string = 'product';
  public static readonly PROFILE: string = 'profile';
  public static readonly USER: string = 'user';
  public static readonly USER_RISK_SCORE: string = 'userriskscore';
  public static readonly WINDOWSPATCH: string = 'windowspatch';
  public static readonly WINDOWSPATCH_CHANGE_EVENT: string = 'windowspatchchangeevent';

  // IDM
  public static readonly LAUNCH: string = 'launch';
  public static readonly LOGIN: string = 'login';
  public static readonly LOGOUT: string = 'logout';

  // Apteligent
  public static readonly APPLOAD: string = 'appload';
  public static readonly BATTERY: string = 'battery';
  public static readonly CRASH_IOS: string = 'crash_ios';
  public static readonly CRASH_ANDROID: string = 'crash_android';
  public static readonly CUSTOM_EVENT: string = 'custom_event';
  public static readonly GROUPED_CRASH_ANDROID: string = 'grouped_crash_android';
  public static readonly GROUPED_CRASH_IOS: string = 'grouped_crash_ios';
  public static readonly HANDLED_EXCEPTION_IOS: string = 'handled_exception_ios';
  public static readonly HANDLED_EXCEPTION_ANDROID: string = 'handled_exception_android';
  public static readonly NET_EVENT: string = 'net_event';
  public static readonly NET_ERROR: string = 'net_error';
  public static readonly NETWORK: string = 'network';
  public static readonly PLUGIN_EXCEPTION: string = 'plugin_exception';
  public static readonly USER_FLOW: string = 'user_flow';

  // User Experience (aka DEEM)
  public static readonly APPS: string = 'apps';
  public static readonly ASSET_INFO: string = 'asset_info';
  public static readonly DEVICES: string = 'devices';
  public static readonly USER_ACTIONS: string = 'user_actions';
  public static readonly SERVICES: string = 'services';
  public static readonly OS_UPDATES: string = 'os_updates';
  public static readonly DEVICE_NET_EVENT: string = 'net_event';
  public static readonly DEVICE_NETWORK_ADAPTER: string = 'device_network_adapter';
  public static readonly DEVICE_PERFORMANCE: string = 'device_performance';
  public static readonly DISPLAY: string = 'display';
  public static readonly HORIZON_APPS: string = 'horizon_apps';
  public static readonly HORIZON_EXPERIENCESCORE_APP: string = 'horizon_experiencescore_app';
  public static readonly HORIZON_EXPERIENCESCORE_CONNECTION_SERVER: string = 'horizon_experiencescore_connection_server';
  public static readonly HORIZON_EXPERIENCESCORE_UAG_APPLIANCE: string = 'horizon_experiencescore_uag_appliance';
  public static readonly HORIZON_RESOURCE_CONSUMPTION: string = 'horizon_resource_consumption';
  public static readonly HORIZON_USER_ACTIONS: string = 'horizon_user_actions';
  public static readonly QUALITY_OF_SERVICE: string = 'quality_of_service';
  public static readonly RESOURCE_CONSUMPTION: string = 'resource_consumption';
  public static readonly SYNTHETIC_URL_MONITORING: string = 'synthetic_url_monitoring';
  public static readonly WEB_APP_METRICS: string = 'web_app_metrics';

  // Trust Network
  public static readonly THREAT: string = 'threat';

  // Internal/Intelligence
  public static readonly APP_ACTIVITY_V2: string = 'app_activity_v2';
  public static readonly AUDIT_LOG: string = 'auditlog';
  public static readonly WORKFLOW_EXECUTION: string = 'workflowexecution';
  public static readonly EXPERIENCESCORE_DESKTOP_APP: string = 'experiencescore_desktop_app';
  public static readonly EXPERIENCESCORE_MOBILE_APP: string = 'experiencescore_mobile_app';
  public static readonly EXPERIENCESCORE_DEVICE: string = 'experiencescore_device';
  public static readonly EXPERIENCESCORE_ORG: string = 'experiencescore_org';
  public static readonly EXPERIENCESCORE_USER: string = 'experiencescore_user';
  public static readonly HORIZON_EXPERIENCESCORE_ORG: string = 'horizon_experiencescore_org';
  public static readonly HORIZON_EXPERIENCESCORE_SESSION_LOGON: string = 'horizon_experiencescore_session_logon';
  public static readonly EXPERIENCESCORE_HORIZON_SESSION: string = 'horizon_experiencescore_session';
  public static readonly VULNERABILITY_SUMMARY: string = 'vulnerability_summary';
  public static readonly DEVICE_PATCH_STATUS: string = 'device_patch_status';
  public static readonly INSIGHTS: string = 'insights';
  public static readonly APP_ACTIVITY: string = 'app_activity';
  public static readonly DEVICE_ACTIVITY: string = 'device_activity';
  public static readonly RCA_SAMPLE: string = 'rca_sample';

  // horizon
  public static readonly CONNECTION_SERVER_GENERAL_TITANCHANGEEVENT: string = 'connection_server_general_titanchangeevent';
  public static readonly EDGE_TITAN: string = 'edge_titan';
  public static readonly POD: string = 'pod';
  public static readonly POOL: string = 'pool';
  public static readonly SESSION: string = 'session';
  public static readonly SESSION_SNAPSHOT: string = 'session_snapshot';
  public static readonly SESSION_SNAPSHOT_TITAN: string = 'session_snapshot_titan';
  public static readonly SESSION_TITAN: string = 'session_titan';
  public static readonly UAG_APPIANCE_INFO_TITANCHANGEEVENT: string = 'uag_appliance_info_titanchangeevent';
  public static readonly VM: string = 'vm';
  public static readonly VM_EVENT_TITAN: string = 'vm_event_titan';
  public static readonly VM_TITAN: string = 'vm_titan';

  // UAG
  public static readonly TUNNEL: string = 'tunnel';

  // Hub
  public static readonly NOTIFICATION: string = 'notification';
  public static readonly NOTIFICATION_ACTION: string = 'notification_action';
  public static readonly SURVEY: string = 'survey';

  // SAM
  public static readonly PRODUCT_CATALOG_METRICS: string = 'product_catalog_metrics';
  public static readonly PRODUCT_CATALOG_METRICS_TREND: string = 'product_catalog_metrics_trend';
}
