/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * CustomAttributeIdentifiersSearchRequest
 * @export
 * @class CustomAttributeIdentifiersSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class CustomAttributeIdentifiersSearchRequest extends GenericSearchRequest {
  @JsonProperty('struct_map_attribute')
  public customAttribute: string;

  /**
   * Creates an instance of CustomAttributeIdentifiersSearchRequest.
   * @param {...Array<Partial<CustomAttributeIdentifiersSearchRequest>>} args
   * @memberof CustomAttributeIdentifiersSearchRequest
   */
  constructor(...args: Array<Partial<CustomAttributeIdentifiersSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
