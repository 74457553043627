/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Tests if attribute string or string containing attribute is fully qualified or not
 *
 * @export
 * @param {string} attributeStr
 * @returns {boolean}
 */
export function isV2Attribute(attributeStr: string): boolean {
  const regex = new RegExp(/\w+\.\w+\./, 'g');
  return regex.test(attributeStr);
}
