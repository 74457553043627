/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import { AggregationWidget } from '@ws1c/intelligence-models/dashboard/aggregation-widget.model';
import { CounterDefinition } from '@ws1c/intelligence-models/dashboard/counter-definition.model';
import { WidgetDataset } from '@ws1c/intelligence-models/dashboard/widget-dataset.model';
import { CustomAttributeIdentifier } from '@ws1c/intelligence-models/filter/custom-attribute-identifier.model';
import { RuleType } from '@ws1c/intelligence-models/filter/rule-type.enum';

/**
 * checkAndAddCustomAttribute
 * @export
 * @param {string} attributeName
 * @param {GenericObject} customAttributeMap
 */
export function checkAndAddCustomAttribute(attributeName: string, customAttributeMap: Map<string, CustomAttributeIdentifier[]>) {
  if (attributeName.includes('->')) {
    const customAttributeArray = attributeName.split('->');
    if (!customAttributeMap.has(customAttributeArray[0])) {
      customAttributeMap.set(customAttributeArray[0], <CustomAttributeIdentifier[]>[]);
    }
    const customAttributeIdentifiers = customAttributeMap.get(customAttributeArray[0]);
    const customAttributeIdentifiersNames = customAttributeIdentifiers?.map(
      (customAttributeIdentifier: CustomAttributeIdentifier) => customAttributeIdentifier.name,
    );
    if (!customAttributeIdentifiersNames.includes(customAttributeArray[1])) {
      customAttributeIdentifiers.push(
        new CustomAttributeIdentifier({
          name: customAttributeArray[1],
        }),
      );
    }
  }
}

/**
 * findAndAddCustomAttributesFromFilter
 * @export
 * @param {GenericObject} ruleSet
 * @param {Map<string, Set<string>>} [result]
 */
export function findAndAddCustomAttributesFromFilter(ruleSet: GenericObject, result?: Map<string, CustomAttributeIdentifier[]>) {
  ruleSet?.rules?.forEach((rule: GenericObject) => {
    if (rule?.type === RuleType[RuleType.RuleSet]) {
      findAndAddCustomAttributesFromFilter(rule, result);
      return;
    }
    if (rule?.type !== RuleType[RuleType.CompositeRule]) {
      return;
    }
    // Merging attribute and identifiers of current custom attribute rule to result
    const attribute = rule.attribute;
    const customAttributeIdentifiers = result.get(attribute) ?? <CustomAttributeIdentifier[]>[];
    const customAttributeIdentifiersNames = customAttributeIdentifiers?.map(
      (customAttributeIdentifier: CustomAttributeIdentifier) => customAttributeIdentifier.name,
    );
    rule?.rule_set?.rules?.forEach((customAttributeFilterRule: GenericObject) => {
      const identifier = customAttributeFilterRule.nested_map_attribute_identifier;
      if (!customAttributeIdentifiersNames.includes(identifier)) {
        customAttributeIdentifiers.push(
          new CustomAttributeIdentifier({
            name: identifier,
          }),
        );
      }
    });
    result.set(attribute, customAttributeIdentifiers);
  });
}

/**
 * getCustomAttributesForWidgets
 * @export
 * @param {AggregationWidget} widget
 * @returns {Map<string, CustomAttributeIdentifier[]>}
 */
export function getCustomAttributesForWidgets(widget: AggregationWidget): Map<string, CustomAttributeIdentifier[]> {
  const customAttributeIdentifiersByAttributeName = new Map<string, CustomAttributeIdentifier[]>();
  widget.widgetDatasets.forEach((widgetDataset: WidgetDataset) => {
    widgetDataset.trend?.trendDefinition?.counterDefinitions?.forEach((counterDefinition: CounterDefinition) => {
      checkAndAddCustomAttribute(counterDefinition.aggregateAttribute, customAttributeIdentifiersByAttributeName);
    });
    widgetDataset.trend?.trendDefinition?.bucketingAttributes?.forEach((bucketingAttribute: string) => {
      checkAndAddCustomAttribute(bucketingAttribute, customAttributeIdentifiersByAttributeName);
    });
    if (widgetDataset.trend?.trendDefinition?.filter_condition_nested_rules) {
      findAndAddCustomAttributesFromFilter(
        widgetDataset.trend.trendDefinition.filter_condition_nested_rules,
        customAttributeIdentifiersByAttributeName,
      );
    }
  });
  return customAttributeIdentifiersByAttributeName;
}
