/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

/**
 * Filter required in Trigger for ConnectorAction, specified in ConnectorActionMetadata
 * @export
 * @class ApplicabilityFilter
 */
export class ApplicabilityFilter {
  @JsonProperty('attribute')
  public attribute: string = undefined;

  @JsonProperty('supported_values')
  public supportedValues: string[] = undefined;

  /**
   * Creates an instance of ApplicabilityFilter
   * @param {Array<Partial<ApplicabilityFilter>>} args
   */
  constructor(...args: Array<Partial<ApplicabilityFilter>>) {
    Object.assign(this, ...args);
  }
}
