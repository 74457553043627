/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { FeatureCategory } from './feature-category.model';
import { FeatureSetupType, IntegratedServiceType } from './integrated-service.enum';
import { TenantType } from './tenant-type.enum';

/**
 * Integrated Service Model
 *
 * @export
 * @class IntegratedService
 */
@Serializable
export class IntegratedService {
  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('doc_url')
  public docUrl: string = undefined;

  @JsonProperty('eligible_for_trial')
  public eligibleForTrial: boolean = undefined;

  @JsonProperty('eligible_for_in_product_trial')
  public eligibleForInProductTrial: boolean = undefined;

  @JsonProperty({ name: 'modules', cls: FeatureCategory })
  public featureCategories: FeatureCategory[] = undefined;

  @JsonProperty('short_title')
  public shortTitle: string = undefined;

  @JsonProperty('show_in_home_screen')
  public showInHomeScreen: boolean = undefined;

  @JsonProperty('show_in_getting_started')
  public showInGettingStarted: boolean = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty('url')
  public url: string = undefined;

  @JsonProperty('tenant_name')
  public tenantName: string = undefined;

  @JsonProperty({ name: 'tenant_type', customConverter: enumConverterFactory(TenantType), excludeToJson: true })
  public tenantType: TenantType = undefined;

  @JsonProperty({ name: 'url_type', customConverter: enumConverterFactory(FeatureSetupType), excludeToJson: true })
  public urlType: FeatureSetupType = undefined;

  /**
   * Creates an instance of IntegratedService
   * @param {Array<Partial<IntegratedService>} args - Initialization arguments for object
   * @memberof IntegratedService
   */
  constructor(...args: Array<Partial<IntegratedService>>) {
    Object.assign(this, ...args);
  }

  /**
   * Returns true if service is non intelligence and entitled
   * @param {boolean} isInProductTrialEnabled
   * @returns {boolean}
   * @memberof IntegratedService
   */
  public isNonIntelligenceServiceEntitled(isInProductTrialEnabled: boolean): boolean {
    if (isInProductTrialEnabled) {
      return !this.isIntelligenceService && !!this.tenantType && this.tenantType !== TenantType.TRIAL;
    }
    return !!this.url;
  }

  /**
   * Returns true if intelligence service is premium - premium license present and service is not in trial when in-product FF is enabled
   * when in-product FF is disabled, it is evaluated based on showBanner
   * @param {boolean} isInProductTrialEnabled
   * @param {boolean} hasPremiumLicense
   * @param {boolean} showBanner
   * @param {boolean} hasDisabledFeatures
   * @returns {boolean}
   * @memberof IntegratedService
   */
  public isIntelligencePremium(
    isInProductTrialEnabled: boolean,
    hasPremiumLicense: boolean,
    showBanner: boolean,
    hasDisabledFeatures: boolean,
  ): boolean {
    // return true if premium license is not directly present but all features are enabled (through other SKU combinations)
    if (!hasPremiumLicense && !hasDisabledFeatures) {
      return true;
    }
    if (isInProductTrialEnabled) {
      return hasPremiumLicense && !this.isTrialActive;
    }
    return !showBanner;
  }

  /**
   * Getter for isTrialActive - returns true if trial is active for a service
   *
   * @readonly
   * @returns {boolean}
   * @memberof IntegratedService
   */
  public get isTrialActive(): boolean {
    return this.tenantType === TenantType.TRIAL;
  }

  /**
   * Getter for isIntelligenceService - returns true if servicetype is intelligence
   *
   * @readonly
   * @returns {boolean}
   * @memberof IntegratedService
   */
  public get isIntelligenceService(): boolean {
    return this.type === IntegratedServiceType.INTELLIGENCE;
  }
}
