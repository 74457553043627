/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ServiceLimitsName
 * @export
 * @enum {string}
 */
export enum ServiceLimitsName {
  AUTOMATIONS = 'MAX_AUTOMATIONS',
  AUTOMATION_ACTIONS = 'MAX_ACTIONS_PER_AUTOMATION',
  AUTOMATION_GROUP_ACTIONS = 'MAX_ACTIONS_PER_ACTION_GROUP',
  AUTOMATION_NESTED_CONDITIONS = 'MAX_NESTED_SWITCH_CONDITIONS_PER_WORKFLOW',
  AUTOMATION_POTENTIAL_IMPACT_TARGETS = 'MAX_POTENTIAL_IMPACT_TARGETS',
  REPORTS = 'MAX_REPORTS',
  DASHBOARDS = 'MAX_DASHBOARDS',
}
