/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * DataType
 * @export
 * @enum {number}
 */
export enum DataType {
  BINARY,
  BOOLEAN,
  COUNTER_INTEGER,
  DATE_AS_STRING,
  DATETIME,
  DOUBLE,
  FLOAT,
  INTEGER,
  IPV4,
  IPV4LIST,
  LONG,
  NUMBERLIST,
  NUMBERSET,
  PERCENTAGE,
  STRING,
  STRING_INTEGER,
  STRINGLIST,
  STRUCTMAP,
  STRINGSET,
  UUID,
}
