/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ApplicabilityFilter } from './applicability-filter.model';

/**
 * ConnectorActionMetadata
 * @export
 * @class ConnectorActionMetadata
 */
@Serializable
export class ConnectorActionMetadata {
  @JsonProperty('action_type')
  public actionType: string = undefined;

  @JsonProperty({ name: 'applicability_filter', cls: ApplicabilityFilter })
  public applicabilityFilter: ApplicabilityFilter[] = undefined;

  @JsonProperty('bulk_action_supported')
  public isBulkActionSupported: boolean = undefined;

  @JsonProperty('callback_action_required')
  public isCallbackActionRequired: boolean = undefined;

  @JsonProperty('recoverable')
  public isRecoverable: boolean = undefined;

  @JsonProperty('unrecoverable_message')
  public unrecoverableMessage: string = undefined;

  @JsonProperty('test_action_supported')
  public isTestActionSupported: boolean = undefined;

  @JsonProperty('ui_hidden')
  public isUiHidden: boolean = undefined;

  /**
   * Creates an instance of ConnectorActionMetadata.
   * @param {...Array<Partial<ConnectorActionMetadata>>} args
   * @memberof ConnectorActionMetadata
   */
  constructor(...args: Array<Partial<ConnectorActionMetadata>>) {
    Object.assign(this, ...args);
  }
}
