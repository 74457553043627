/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Validators } from '@angular/forms';

import { SettingsFilterTag } from '@ws1c/intelligence-models/common/';
import { ServicePermission } from './available-service.model';
import { ServicePermissionType } from './connection.enum';
import { OAuthPartnerType } from './oauth-connection.enum';
import { PartnerFormProperties } from './partner-form-properties.interface';
import { TrustNetworkPartnerType } from './trust-network-partner-connection.enum';

/**
 * PartnerConnectionConfig
 * @export
 * @class PartnerConnectionConfig
 */
export class PartnerConnectionConfig {
  public static readonly oAuthPartnerTagLabels: Record<OAuthPartnerType, string> = {
    [OAuthPartnerType.ZOOM]: 'SETTINGS.EMPLOYEE_EXPERIENCE',
    [OAuthPartnerType.MICROSOFT]: 'SETTINGS.EMPLOYEE_EXPERIENCE',
  };
  public static readonly oAuthPartnerSettingsTag: Record<OAuthPartnerType, SettingsFilterTag> = {
    [OAuthPartnerType.ZOOM]: SettingsFilterTag.EMPLOYEE_EXPERIENCE,
    [OAuthPartnerType.MICROSOFT]: SettingsFilterTag.EMPLOYEE_EXPERIENCE,
  };

  public static readonly partnerAccordionCredentialTitleMap: Record<TrustNetworkPartnerType, string> = {
    [TrustNetworkPartnerType.BETTERMOBILE]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
    [TrustNetworkPartnerType.CHECKPOINT]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
    [TrustNetworkPartnerType.CROWDSTRIKE]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
    [TrustNetworkPartnerType.DELL]: 'CONNECTIONS.CREDENTIALS',
    [TrustNetworkPartnerType.EXTREME_NETWORKS]: undefined,
    [TrustNetworkPartnerType.PRADEO]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
    [TrustNetworkPartnerType.WANDERA]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
    [TrustNetworkPartnerType.ZIMPERIUM]: 'CONNECTIONS.TRUST_NETWORK_PARTNER.CREDENTIALS',
  };

  public static readonly isPartnerAcknowledgeFormMap: Record<TrustNetworkPartnerType, boolean> = {
    [TrustNetworkPartnerType.BETTERMOBILE]: false,
    [TrustNetworkPartnerType.CHECKPOINT]: false,
    [TrustNetworkPartnerType.CROWDSTRIKE]: false,
    [TrustNetworkPartnerType.DELL]: false,
    [TrustNetworkPartnerType.EXTREME_NETWORKS]: true,
    [TrustNetworkPartnerType.PRADEO]: false,
    [TrustNetworkPartnerType.WANDERA]: false,
    [TrustNetworkPartnerType.ZIMPERIUM]: false,
  };

  public static readonly showPartnerPermissionsAccordionRow: Record<TrustNetworkPartnerType, boolean> = {
    [TrustNetworkPartnerType.BETTERMOBILE]: false,
    [TrustNetworkPartnerType.CHECKPOINT]: false,
    [TrustNetworkPartnerType.CROWDSTRIKE]: false,
    [TrustNetworkPartnerType.DELL]: true,
    [TrustNetworkPartnerType.EXTREME_NETWORKS]: false,
    [TrustNetworkPartnerType.PRADEO]: false,
    [TrustNetworkPartnerType.WANDERA]: false,
    [TrustNetworkPartnerType.ZIMPERIUM]: false,
  };

  public static readonly partnerDynamicFieldPropertiesMap: Record<string, PartnerFormProperties> = {
    [TrustNetworkPartnerType.DELL]: {
      description: undefined,
      isSubmitToken: true,
      helpUrlText: 'CONNECTIONS.TRUST_NETWORK_PARTNER.DELL.HELP_URL_TEXT',
      servicePermissions: [
        new ServicePermission({
          permissionType: ServicePermissionType.ACTION,
          label: 'CONNECTIONS.TRUST_NETWORK_PARTNER.DELL.WARRANTY_TEXT',
        }),
      ],
      fields: [
        {
          name: 'clientId',
          label: 'SERVICE_ACCOUNTS.CLIENT_ID',
          showMask: true,
          validators: [
            {
              func: Validators.required,
              errorMsg: undefined,
              errorKey: 'required',
            },
          ],
        },
        {
          name: 'clientSecret',
          label: 'CONNECTIONS.SECRET_KEY',
          showMask: true,
          validators: [
            {
              func: Validators.required,
              errorMsg: undefined,
              errorKey: 'required',
            },
          ],
        },
      ],
    },
  };

  public static readonly employeeExperiencePartners: string[] = [
    TrustNetworkPartnerType.EXTREME_NETWORKS,
    OAuthPartnerType.ZOOM,
    OAuthPartnerType.MICROSOFT,
  ];
}
