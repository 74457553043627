/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * ScopeSetAccessOption
 * @export
 * @enum {number}
 */
export enum ScopeSetAccessOption {
  EDIT,
  EXECUTE,
  NO_ACCESS,
  READ_ONLY,
}
