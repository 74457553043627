/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * GraphNodeType
 * Enum representing the different node types
 * @export
 * @enum {string}
 */
export enum GraphNodeType {
  WORKFLOW_SETTINGS = 'workflow-settings',
  ACTION = 'action',
  CONDITION = 'condition',
  GROUP = 'group',
  OPTIONS = 'options',
  END_BRANCH = 'end-branch',
  NEXT_STEP = 'next-step',
  STOP_WORKFLOW = 'stop-workflow',
}
