/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemPlaybookType
 * @export
 * @enum {string}
 */
export enum DeemPlaybookType {
  SINGLE_DEVICE = 'SINGLE_DEVICE',
  MULTI_DEVICE = 'MULTI_DEVICE',
  SINGLE_APP = 'SINGLE_APP',
}
