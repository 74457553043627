/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { DeemPlaybookType } from './deem-playbook-type.enum';
import { DeemPlaybookVersionDetail } from './deem-playbook-version-detail.model';

/**
 * DeemPlaybookVersionDetailView
 * @export
 * @class DeemPlaybookVersionDetailView
 * @extends {UserTouchDetails}
 */
@Serializable
export class DeemPlaybookVersionDetailView extends UserTouchDetails {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty({ name: 'playbook_type', customConverter: enumConverterFactory(DeemPlaybookType) })
  public playbookType: DeemPlaybookType = undefined;

  @JsonProperty({ name: 'playbook_versions_list', cls: DeemPlaybookVersionDetail })
  public playbookVersionList: DeemPlaybookVersionDetail[] = [];

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  /**
   * Initializes instance of DeemPlaybookVersionDetailView
   * @param {Array<Partial<DeemPlaybookVersionDetailView>>} args
   * @memberof DeemPlaybookVersion
   */
  constructor(...args: Array<Partial<DeemPlaybookVersionDetailView>>) {
    super(...args);
    Object.assign(this, ...args);
  }
}
