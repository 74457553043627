/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { DataDecorator, DataUnit, GenericObject } from '@dpa/ui-common';

import { AppPlatform } from '@ws1c/intelligence-models/app-loads/app-platform.model';
import { ROUTE_NAMES } from '@ws1c/intelligence-models/app.routes.names';
import { ComposeFunction, StandardWidgetSubtype } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { Entity } from '@ws1c/intelligence-models/entity.model';
import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { Column } from '@ws1c/intelligence-models/integration-meta/column.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { RawThresholdAttribute } from '@ws1c/intelligence-models/threshold/raw-threshold-attribute.enum';
import { DashboardColors } from './dashboard-color.enum';
import { DeviceInfoType, EdiType } from './edi-type.enum';

/**
 * tableColumnValue
 * @export
 * @interface TableColumnValue
 */
export interface TableColumnValue {
  original: number;
  formatted: string;
}

/**
 * DevicesDashboardConfig
 * @export
 * @class DevicesDashboardConfig
 */
export class DevicesDashboardConfig {
  public static scoreDefault = { poor: 0, neutral: 0, good: 0, total: 0 };
  public static performanceSubType = {
    APP: 'app',
    DESTINATION: 'destination',
    DEVICE: 'device',
    NETWORK: 'network',
    SYNTHETIC_URL_MONITORING: 'syntheticUrlMonitoring',
  };
  public static types = [
    { key: this.performanceSubType.DEVICE, label: 'STANDARD_DASHBOARD.USER_PROFILE.DEVICE_HEALTH' },
    { key: this.performanceSubType.NETWORK, label: 'COMMON_MESSAGES.NETWORK' },
  ];
  public static deviceExperienceScoreMap = {
    device: [
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_crash_count_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.CRASH_COUNT',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.CRASH_COUNT,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_boot_time_millis_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVERAGE_BOOT_TIME_MILLIS',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_shutdown_time_millis_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVERAGE_SHUTDOWN_TIME_MILLIS',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_battery_health_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_HEALTH',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.BATTERY_HEALTH,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_cpu_health_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.CPU_HEALTH',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.CPU_HEALTH,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_gpu_health_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU_HEALTH',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.GPU_HEALTH,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_memory_health_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_HEALTH',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.MEMORY_HEALTH,
      },
    ],
    network: [
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_wifi_strength_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.WIFI_STRENGTH',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.WIFI_STRENGTH,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_download_speed_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DOWNLOAD_SPEED',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.DOWNLOAD_SPEED,
      },
      {
        name: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_upload_speed_score,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.UPLOAD_SPEED',
        score: 'NA',
        value: undefined,
        threshold: undefined,
        thresholdKey: RawThresholdAttribute.UPLOAD_SPEED,
      },
    ],
  };
  public static readonly deviceProfileSummaryType = {
    APPLICATIONS: 'Applications',
    DESTINATIONS: 'Destinations',
    DEVICE: 'Device',
    ENERGY_CONSUMPTION: 'EnergyConsumption',
    NETWORK: 'Network',
    SYNTHETIC_URL_MONITORING: 'SyntheticUrlMonitoring',
    ZOOM: 'Zoom',
  };
  public static readonly deviceProfileSummaryDetails = [
    { summaryType: this.deviceProfileSummaryType.APPLICATIONS, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_PERFORMANCE' },
    { summaryType: this.deviceProfileSummaryType.DEVICE, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_PERFORMANCE' },
    { summaryType: this.deviceProfileSummaryType.NETWORK, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.NETWORK_PERFORMANCE' },
    { summaryType: this.deviceProfileSummaryType.DESTINATIONS, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DESTINATIONS' },
    { summaryType: this.deviceProfileSummaryType.ENERGY_CONSUMPTION, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ENERGY_CONSUMPTION' },
  ];
  public static readonly mobileDeviceProfileSummaryDetails = {
    [AppPlatform.ANDROID]: [
      { summaryType: this.deviceProfileSummaryType.DEVICE, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_PERFORMANCE' },
      { summaryType: this.deviceProfileSummaryType.NETWORK, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.NETWORK_PERFORMANCE' },
    ],
    [AppPlatform.APPLE_IOS]: [
      { summaryType: this.deviceProfileSummaryType.DEVICE, label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_PERFORMANCE' },
    ],
  };
  public static readonly COMPOSE_CONFIGS_BY_DEVICES_DASHBOARD = {
    DEVICES_ERRORS_DESKTOP: {
      __DEVICES_ERRORS_DESKTOP_RATIO: {
        dependencies: ['DEVICES_ERRORS_DESKTOP_COUNT', 'DEVICES_BOOT_DESKTOP_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __DEVICES_ERRORS_DESKTOP_RATIO_PREVIOUS_PERIOD: {
        dependencies: ['DEVICES_ERRORS_DESKTOP_COUNT_PREVIOUS_PERIOD', 'DEVICES_BOOT_DESKTOP_COUNT_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
    },
    DEVICES_NETWORK_DESKTOP: {
      __DEVICES_NETWORK_DESKTOP_WIFI: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_WIFI'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_UPLOAD: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_UPLOAD'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_DOWNLOAD: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_DOWNLOAD'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_PROTOCOL: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_PROTOCOL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_FREQUENCY: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_FREQUENCY'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_OS_VERSION: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_OS_VERSION'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_NETWORK_DESKTOP_MODEL: {
        dependencies: ['DEVICES_NETWORK_DESKTOP_MODEL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
    },
    DEVICES_OVERVIEW_DESKTOP: {
      __DEVICES_OVERVIEW_BATTERY_HEALTH_TREND: {
        dependencies: ['DEVICES_BATTERY_GOOD_TREND', 'DEVICES_BATTERY_MEDIUM_TREND', 'DEVICES_BATTERY_POOR_TREND'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Battery Health',
          seriesName: '_merged_series',
          seriesValues: ['Good', 'Medium', 'Poor'],
        },
      },
    },
    DEVICES_DETAILS: {
      __DEVICES_DETAILS_NATIVE_APPS_INSTALLED: {
        dependencies: ['DEVICES_DETAILS_NATIVE_APPS_INSTALLED'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_COUNT_GROUPS: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED',
          'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_CRASH_COUNT',
          'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_HANG_COUNT',
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      __DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_USAGE: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_0_USAGE',
          'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_1_USAGE',
          'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_2_USAGE',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'GPU Usage',
          seriesName: '_merged_series',
          seriesValues: ['GPU 0', 'GPU 1', 'GPU 2'],
        },
      },
    },
    DEVICES_PERFORMANCE_DESKTOP_BOOT_SHUTDOWN: {
      __DEVICES_BOOT_TIME_BUBBLE_NO_ZEROS: {
        dependencies: ['__DEVICES_BOOT_TIME_BUBBLE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_LOGIN_TIME_BUBBLE_NO_ZEROS: {
        dependencies: ['__DEVICES_LOGIN_TIME_BUBBLE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_SHUTDOWN_TIME_BUBBLE_NO_ZEROS: {
        dependencies: ['__DEVICES_SHUTDOWN_TIME_BUBBLE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_BOOT_TIME_BUBBLE: {
        dependencies: ['DEVICES_PERFORMANCE_BOOT_TIME_WARNING', 'DEVICES_PERFORMANCE_BOOT_TIME_CRITICAL'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Risk Level',
          seriesName: '_merged_series',
          seriesValues: ['Warning', 'Critical'],
        },
      },
      __DEVICES_LOGIN_TIME_BUBBLE: {
        dependencies: ['DEVICES_PERFORMANCE_LOGIN_TIME_WARNING', 'DEVICES_PERFORMANCE_LOGIN_TIME_CRITICAL'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Risk Level',
          seriesName: '_merged_series',
          seriesValues: ['Warning', 'Critical'],
        },
      },
      __DEVICES_SHUTDOWN_TIME_BUBBLE: {
        dependencies: ['DEVICES_PERFORMANCE_SHUTDOWN_TIME_WARNING', 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_CRITICAL'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Risk Level',
          seriesName: '_merged_series',
          seriesValues: ['Warning', 'Critical'],
        },
      },
      __DEVICES_BOOT_DEGRADATION: {
        dependencies: ['DEVICES_BOOT_DEGRADATION'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_SHUTDOWN_DEGRADATION: {
        dependencies: ['DEVICES_SHUTDOWN_DEGRADATION'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
    },
    DEVICES_PERFORMANCE_DESKTOP: {
      __DEVICES_PERFORMANCE_CPU_TEMPERATURE_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_CPU_TEMPERATURE_DEVICES', '__DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_CRASH_COUNT_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_CRASH_DEVICES', '__DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_CPU_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_CPU_DEVICES', '__DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_GPU_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_GPU_DEVICES', '__DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_MEMORY_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_MEMORY_DEVICES', '__DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_BATTERY_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_BATTERY_DEVICES', '__DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_BOOT_TIME_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_BOOT_TIME_DEVICES', '__DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_SHUTDOWN_TIME_TABLE: {
        dependencies: ['DEVICES_PERFORMANCE_SHUTDOWN_TIME_DEVICES', '__DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE: {
        dependencies: ['DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
    },
    DEVICES_PROFILE: {
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_DEVICE',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APP',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_ACTIONS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_OS_UPDATES',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5],
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_DEVICE',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APP',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_ACTIONS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_OS_UPDATES',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5, 6],
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS: {
        dependencies: [
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5],
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS_ZOOM: {
        dependencies: [
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5, 6],
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID: {
        dependencies: [
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5],
        },
      },
      __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID_ZOOM: {
        dependencies: [
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',
          '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [1, 2, 3, 4, 5, 6],
        },
      },
      __DEVICES_PROFILE_LOGIN_HISTORY: {
        dependencies: ['DEVICES_PROFILE_LOGIN_HISTORY'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS: {
        dependencies: [
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_DEVICES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_DISPLAY',
          'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          'DEVICES_PROFILE_TIMELINE_EVENTS_OS_UPDATES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_SERVICES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_USER_ACTIONS',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM: {
        dependencies: [
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_DEVICES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_DISPLAY',
          'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          'DEVICES_PROFILE_TIMELINE_EVENTS_OS_UPDATES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_SERVICES',
          'DEVICES_PROFILE_TIMELINE_EVENTS_USER_ACTIONS',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS_IOS: {
        dependencies: [
          '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS_IOS_ZOOM: {
        dependencies: [
          '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID: {
        dependencies: [
          '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID_ZOOM: {
        dependencies: [
          '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',
          '_DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      _DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW: {
        dependencies: ['DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_SUCCESS', 'DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_FAILED'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'apteligent.user_flow._state_name',
          seriesValues: ['User Flow Success', 'User Flow Failure'],
        },
      },
      _DEVICES_PROFILE_TIMELINE_EVENTS_APPS: {
        dependencies: [
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_CRASH',
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_LOAD',
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_INSTALLS',
          'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_HANDLED_EXCEPTIONS',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'App Events',
          seriesValues: ['App Crash', 'App Load', 'App Installs', 'Handled Exceptions'],
        },
      },
      _DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK: {
        dependencies: ['DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_ERRORS', 'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_EVENTS'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Network Events',
          seriesValues: ['Network Errors', 'Network Events'],
        },
      },
      _DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS: {
        dependencies: ['DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_FAILED', 'DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_APP_LAUNCH'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Access Events',
          seriesValues: ['Failed Logins', 'App Launch'],
        },
      },
      _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_SUCCESS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_FAILED',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'apteligent.user_flow._state_name',
          seriesValues: ['User Flow Success', 'User Flow Failure'],
        },
      },
      _DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM: {
        dependencies: ['DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Zoom Meetings',
          seriesValues: ['Zoom Meetings'],
        },
      },
      _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM: {
        dependencies: ['DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Zoom Meetings',
          seriesValues: ['Zoom Meetings'],
        },
      },
      _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_CRASH',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_LOAD',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_INSTALLS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_HANDLED_EXCEPTIONS',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'App Events',
          seriesValues: ['App Crash', 'App Load', 'App Installs', 'Handled Exceptions'],
        },
      },
      _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_ERRORS',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_EVENTS',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Network Events',
          seriesValues: ['Network Errors', 'Network Events'],
        },
      },
      _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS: {
        dependencies: [
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_FAILED',
          'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_APP_LAUNCH',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Access Events',
          seriesValues: ['Failed Logins', 'App Launch'],
        },
      },
    },
    DEVICES_PROFILE_PERFORMANCE: {
      __DEVICES_PROFILE_APP_SYSTEM: {
        dependencies: [
          'DEVICES_PROFILE_APP_SYSTEM_CPU',
          'DEVICES_PROFILE_APP_SYSTEM_GPU_0',
          'DEVICES_PROFILE_APP_SYSTEM_GPU_1',
          'DEVICES_PROFILE_APP_SYSTEM_GPU_2',
          'DEVICES_PROFILE_APP_SYSTEM_MEMORY',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['CPU', 'GPU0', 'GPU1', 'GPU2', 'Memory'],
        },
      },
      __DEVICES_PROFILE_APP_NETWORK: {
        dependencies: ['DEVICES_PROFILE_APP_NETWORK', 'DEVICES_PROFILE_APP_DISK'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Network', 'Disk'],
        },
      },
      __DEVICES_PROFILE_NETWORK_SPEED: {
        dependencies: ['DEVICES_PROFILE_NETWORK_DOWNLOAD', 'DEVICES_PROFILE_NETWORK_UPLOAD'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['DownLoad Speed', 'Upload Speed'],
        },
      },
      __DEVICES_PROFILE_DESTINATION_BY_URL: {
        dependencies: [
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_TOTAL,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_REDIRECT,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_DNS,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_CONNECTION,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_TLS,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_REQUEST,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_RESPONSE,
          StandardWidgetSubtype.DEVICES_PROFILE_DESTINATION_BY_URL_RENDER,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Duration',
          seriesValues: [
            'Total Negotiation',
            'Redirect',
            'DNS Lookup',
            'Connection Setup',
            'TLS Negotiation',
            'HTTP Request',
            'HTTP Response',
            'Render',
          ],
        },
      },
      __DEVICES_PROFILE_SYNTHETIC_URL_TEST_RESULTS: {
        dependencies: [
          'DEVICES_PROFILE_AVG_TOTAL_RESPONSE_TIME',
          'DEVICES_PROFILE_AVG_NAME_RESPONSE_TIME',
          'DEVICES_PROFILE_AVG_SOCKET_CONNECTION_TIME',
          'DEVICES_PROFILE_AVG_TLS_SETUP_TIME',
          'DEVICES_PROFILE_AVG_HTTP_RESPONSE_TIME',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Total Response Time', 'Name Response Time', 'Socket Connection Time', 'TLS Setup Time', 'HTTP Response Time'],
        },
      },
      __DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY_WITH_SUCCESSES_COUNT: {
        dependencies: [
          'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY',
          'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUCCESSES_COUNT',
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { count: 0 },
          mergedBucketName: 'successes',
          mergedBucketIndex: 0,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY: {
        dependencies: [
          '__DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY_WITH_SUCCESSES_COUNT',
          'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_FAILURES_COUNT',
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { count: 0 },
          mergedBucketName: 'failures',
          mergedBucketIndex: 0,
          mergedCounterIndex: 0,
        },
      },
      __DEVICES_PROFILE_NETWORK_ERROR: {
        dependencies: ['DEVICES_PROFILE_NETWORK_ERROR_INBOUND', 'DEVICES_PROFILE_NETWORK_ERROR_OUTBOUND'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Network Interface Packets Inbound Errors', 'Network Interface Packets Outbound Errors'],
        },
      },
      __DEVICES_PROFILE_PROCESSOR_TIME_PERFORMANCE: {
        dependencies: [
          'DEVICES_PROFILE_PROCESSOR_C1',
          'DEVICES_PROFILE_PROCESSOR_C2',
          'DEVICES_PROFILE_PROCESSOR_C3',
          'DEVICES_PROFILE_PROCESSOR_TOTAL',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Processor Time C1', 'Processor Time C2', 'Processor Time C3', 'Processor Total Time'],
        },
      },
      __DEVICES_PROFILE_ENERGY: {
        dependencies: [
          'DEVICES_PROFILE_ENERGY_CPU',
          'DEVICES_PROFILE_ENERGY_DISK',
          'DEVICES_PROFILE_ENERGY_DISPLAY',
          'DEVICES_PROFILE_ENERGY_NETWORK',
          'DEVICES_PROFILE_ENERGY_SYSTEM',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Consumption',
          seriesValues: ['CPU', 'Disk', 'Display', 'Network', 'System on Chips'],
        },
      },
      __DEVICES_PROFILE_PROCESSOR_COUNTERS_PERFORMANCE: {
        dependencies: [
          'DEVICES_PROFILE_PROCESSOR_TOTAL_INTERRUPT',
          'DEVICES_PROFILE_PROCESSOR_TOTAL_PRIVILEGED',
          'DEVICES_PROFILE_PROCESSOR_TOTAL_IDLE',
          'DEVICES_PROFILE_PROCESSOR_TOTAL_USER',
          'DEVICES_PROFILE_PROCESSOR_TOTAL_DPC',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: [
            'Processor Total Interrupt Time',
            'Processor Total Privileged Time',
            'Processor Idle Time',
            'Processor Total User Time',
            'Processor Total DPC Time',
          ],
        },
      },
      __DEVICES_PROFILE_OTHERS_BATTERY: {
        dependencies: ['DEVICES_PROFILE_OTHERS_BATTERY_CHARGE', 'DEVICES_PROFILE_OTHERS_BATTERY_DISCHARGE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Battery Status Charge Rate', 'Battery Status Discharge Rate'],
        },
      },
      __DEVICES_PROFILE_OTHERS_GPU: {
        dependencies: ['DEVICES_PROFILE_OTHERS_GPU_0', 'DEVICES_PROFILE_OTHERS_GPU_1', 'DEVICES_PROFILE_OTHERS_GPU_2'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['GPU 0 Usage', 'GPU 1 Usage', 'GPU 2 Usage'],
        },
      },
      __DEVICES_PROFILE_MEMORY_COUNTERS: {
        dependencies: ['DEVICES_PROFILE_MEMORY_PAGE', 'DEVICES_PROFILE_MEMORY_PAGE_READ', 'DEVICES_PROFILE_MEMORY_PAGE_WRITE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Memory Pages Per Sec', 'Memory Page Reads Per Sec', 'Memory Page Writes Per Sec'],
        },
      },
      __DEVICES_PROFILE_MEMORY_FAULTS: {
        dependencies: [
          'DEVICES_PROFILE_MEMORY_PAGE_FAULT',
          'DEVICES_PROFILE_MEMORY_CACHE_FAULT',
          'DEVICES_PROFILE_MEMORY_TRANSITION_FAULT',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Memory Page Faults Per Sec', 'Memory Cache Faults Per Sec', 'Memory Transition Faults Per Sec'],
        },
      },
      __DEVICES_PROFILE_MEMORY_USAGE: {
        dependencies: ['DEVICES_PROFILE_MEMORY_USAGE', 'DEVICES_PROFILE_MEMORY_PAGING_USAGE', 'DEVICES_PROFILE_MEMORY_COMMITED_USAGE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Memory Usage', 'Paging File Usage', 'Memory Commited Bytes in Use'],
        },
      },
      __DEVICES_PROFILE_DISK_QUEUE: {
        dependencies: ['DEVICES_PROFILE_DISK_QUEUE', 'DEVICES_PROFILE_DISK_QUEUE_READ', 'DEVICES_PROFILE_DISK_QUEUE_WRITE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Disk Queue Length', 'Disk Read Queue Length', 'Disk Write Queue Length'],
        },
      },
      __DEVICES_PROFILE_DISK_OPERATION: {
        dependencies: ['DEVICES_PROFILE_DISK_OPERATION', 'DEVICES_PROFILE_DISK_OPERATION_READ', 'DEVICES_PROFILE_DISK_OPERATION_WRITE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Disk Byte Transfer', 'Disk Byte Read', 'Disk Byte Write'],
        },
      },
      __DEVICES_PROFILE_DISK_OPERATION_TIME: {
        dependencies: [
          'DEVICES_PROFILE_DISK_OPERATION_TIME',
          'DEVICES_PROFILE_DISK_OPERATION_TIME_READ',
          'DEVICES_PROFILE_DISK_OPERATION_TIME_WRITE',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Disk Idle Time', 'Disk Read Time', 'Disk Write Time'],
        },
      },
      __DEVICES_PROFILE_DISK_RESPONSE: {
        dependencies: ['DEVICES_PROFILE_DISK_RESPONSE', 'DEVICES_PROFILE_DISK_RESPONSE_READ', 'DEVICES_PROFILE_DISK_RESPONSE_WRITE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Performance',
          seriesValues: ['Disk Transfer Per Sec', 'Disk Read Per Sec', 'Disk Write Per Sec'],
        },
      },
    },
  };
  public static readonly wifiSignalColors = [
    {
      name: '<20%',
      value: '#b0e1f8',
    },
    {
      name: '20% - 40%',
      value: '#7ecdf1',
    },
    {
      name: '40% - 60%',
      value: '#29abe7',
    },
    {
      name: '60% - 80%',
      value: '#068fd4',
    },
    {
      name: '80% - 100%',
      value: '#026cac',
    },
  ];
  public static readonly speedColors = [
    {
      name: '<25',
      value: '#b0e1f8',
    },
    {
      name: '25 - 100',
      value: '#7ecdf1',
    },
    {
      name: '100 - 200',
      value: '#29abe7',
    },
    {
      name: '200 - 500',
      value: '#068fd4',
    },
    {
      name: '500 - 1000+',
      value: '#026cac',
    },
  ];
  public static readonly completeStatus = ['complete', 'Complete'];
  public static readonly logonExclusionTypes = ['Service', 'System'];
  public static readonly logonTypes = [
    'CachedInteractive',
    'CachedRemoteInteractive',
    'Interactive',
    'NewCredentials',
    'RemoteInteractive',
    'Unlock',
  ];
  public static readonly appInstallsEventNames = ['app_is_installed'];
  public static readonly devicesDashboardFilterColumns = [
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
  ];
  public static readonly TIMELINE_MAX_DATA_POINTS = 61;
  public static readonly performanceEvent: Record<string, string> = {
    BATTERY_AVG: 'BATTERY_AVG',
    BATTERY_CHARGING_RATE: 'BATTERY_CHARGING_RATE',
    BATTERY_LEVEL: 'BATTERY_LEVEL',
    BATTERY_TEMPERATURE: 'BATTERY_TEMPERATURE',
    BATTERY_VOLTAGE: 'BATTERY_VOLTAGE',
    BOOT_AVG: 'BOOT_AVG',
    BOOT_DEGRADATION: 'BOOT_DEGRADATION',
    CPU: 'CPU',
    DISK_AVG: 'DISK_AVG',
    DISK_OPERATION: 'DISK_OPERATION',
    DISK_OPERATION_TIME: 'DISK_OPERATION_TIME',
    DISK_QUEUE: 'DISK_QUEUE',
    DISK_RESPONSE: 'DISK_RESPONSE',
    JITTER: 'JITTER',
    LATENCY: 'LATENCY',
    MEMORY_ALLOCATED_BYTES: 'MEMORY_ALLOCATED_BYTES',
    MEMORY_AVG: 'MEMORY_AVG',
    MEMORY_COUNTERS: 'MEMORY_COUNTERS',
    MEMORY_FAULTS: 'MEMORY_FAULTS',
    MEMORY_SUMMARY: 'MEMORY_SUMMARY',
    MEMORY_TOTAL_BYTES: 'MEMORY_TOTAL_BYTES',
    MEMORY_USAGE: 'MEMORY_USAGE',
    MOBILE_SIGNAL_STRENGTH: 'MOBILE_SIGNAL_STRENGTH',
    NETWORK: 'NETWORK',
    NETWORK_AVG: 'NETWORK_AVG',
    NETWORK_CALLS: 'NETWORK_CALLS',
    NETWORK_DATA_IN: 'NETWORK_DATA_IN',
    NETWORK_DATA_OUT: 'NETWORK_DATA_OUT',
    NETWORK_ERROR_AVG: 'NETWORK_ERROR_AVG',
    NETWORK_ERROR_INOUT_BOUND: 'NETWORK_ERROR_INOUT_BOUND',
    NETWORK_ERRORS: 'NETWORK_ERRORS',
    NETWORK_LATENCY: 'NETWORK_LATENCY',
    OTHERS_AVG: 'OTHERS_AVG',
    OTHERS_BATTERY: 'OTHERS_BATTERY',
    OTHERS_DISK_FREE_SPACE: 'OTHERS_DISK_FREE_SPACE',
    OTHERS_GPU: 'OTHERS_GPU',
    OTHERS_TEMPERATURE: 'OTHERS_TEMPERATURE',
    PERFORMANCE_AVG: 'PERFORMANCE_AVG',
    PROCESSOR_AVG: 'PROCESSOR_AVG',
    PROCESSOR_COUNTERS: 'PROCESSOR_COUNTERS',
    PROCESSOR_INTERRUPTS: 'PROCESSOR_INTERRUPTS',
    PROCESSOR_TIME: 'PROCESSOR_TIME',
    SIGNAL: 'SIGNAL',
    SPEED: 'SPEED',
    STORAGE_DISK_SPACE_FREE_BYTES: 'STORAGE_DISK_SPACE_FREE_BYTES',
    STORAGE_DISK_SPACE_TOTAL_BYTES: 'STORAGE_DISK_SPACE_TOTAL_BYTES',
    STORAGE_SD_CARD_SPACE_FREE_BYTES: 'STORAGE_SD_CARD_SPACE_FREE_BYTES',
    STORAGE_SD_CARD_SPACE_TOTAL_BYTES: 'STORAGE_SD_CARD_SPACE_TOTAL_BYTES',
    STORAGE_SUMMARY: 'STORAGE_SUMMARY',
    WIFI_DOWNLOAD_SPEED: 'WIFI_DOWNLOAD_SPEED',
    WIFI_NETWORK_FREQUENCY: 'WIFI_NETWORK_FREQUENCY',
    WIFI_SIGNAL_STRENGTH: 'WIFI_SIGNAL_STRENGTH',
    WIFI_UPLOAD_SPEED: 'WIFI_UPLOAD_SPEED',
  };
  // desktop
  public static readonly processorKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_c1_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TIME_C1',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_c2_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TIME_C2',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_c3_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TIME_C3',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_dpc_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_DPC_TIME',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_processor_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_TIME',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_interrupt_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_INTERRUPT_TIME',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_interrupts_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_INTERRUPTS_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_privileged_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_PRIVILEGED_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_idle_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_IDLE_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_user_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR_TOTAL_USER_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
  };
  public static readonly bootKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BOOT_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOAD_DEGREDATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_main_pathload_duration_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MAIN_PATH_LOAD_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_pnp_load_duration_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PNP_LOAD_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_services_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SERVICE_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_system_session_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SYSTEM_SESSION_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user_session_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER_SESSION_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_load_duration_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGON_LOAD_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_waiting_duration_millis]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGON_WAITING_DURATION',
      dataType: DataDecorator.TIME_DURATION,
    },
  };
  public static readonly diskKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_bytes_queue_length]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_QUEUE_LENGTH',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_read_queue_length]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_READ_QUEUE_LENGTH',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_write_queue_length]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_WRITE_QUEUE_LENGTH',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_bytes_transfer]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_BYTE_TRANSFER',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_bytes_read]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_BYTE_READ',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_bytes_write]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_BYTE_WRITE',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_idle_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_IDLE_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_read_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_READ_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_write_totaltime]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_WRITE_TIME',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_sec_transfer]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_TRANSFER_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_sec_read]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_READ_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_physicaldisk_avg_disk_sec_write]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_WRITE_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
  };
  public static readonly memoryKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_pages_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_PAGES_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_page_reads_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_PAGE_READS_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_page_writes_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_PAGE_WRITES_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_page_faults_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_PAGE_FAULTS_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_cache_faults_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_CACHE_FAULTS_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_transition_faults_sec]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_TRANSITION_FAULTS_PER_SEC',
      dataType: DataDecorator.COUNTER_PER_SEC,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_usage_percent]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_USAGE',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_paging_file_usage]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PAGING_FILE_USAGE',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_commited_bytes_in_use]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_COMMITED_BYTES_IN_USE',
      dataType: DataDecorator.STRING_PERCENT,
    },
  };
  public static readonly othersKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_thermal_zone_information_zone_temperature]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.THERMAL_ZONE_TEMPERATURE',
      dataType: DataDecorator.TEMPERATURE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_logicaldisk_free_space_mbytes]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGICAL_DISK_FREE_SPACE',
      dataType: DataDecorator.SIZE_MB,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_battery_status_charge_rate]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_STATUS_CHARGE_RATE',
      dataType: DataDecorator.PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_battery_status_discharge_rate]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_STATUS_DISCHARGE_RATE',
      dataType: DataDecorator.PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_gpu_usage0]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU_0_USAGE',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_gpu_usage1]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU_1_USAGE',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_gpu_usage2]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU_2_USAGE',
      dataType: DataDecorator.STRING_PERCENT,
    },
  };
  // mobile
  public static readonly batteryKeyValuePairMap = {
    [AppPlatform.ANDROID]: {
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_rate]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_CHARGING_RATE',
        dataType: DataDecorator.STRING_PERCENT,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_level]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_LEVEL',
        dataType: DataDecorator.STRING_PERCENT,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_voltage]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_VOLTAGE',
        dataType: DataDecorator.VOLTAGE,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_temperature]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_TEMPERATURE',
        dataType: DataDecorator.TEMPERATURE_C,
      },
    },
    [AppPlatform.APPLE_IOS]: {
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_rate]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_CHARGING_RATE',
        dataType: DataDecorator.STRING_PERCENT,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_level]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_LEVEL',
        dataType: DataDecorator.STRING_PERCENT,
      },
    },
  };
  public static readonly mobileMemoryKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_memory_total_bytes]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_TOTAL_BYTES',
      dataType: DataDecorator.SIZE_BYTE,
    },
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_memory_allocated_per_app_bytes]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY_ALLOCATED_BYTES',
      dataType: DataDecorator.SIZE_BYTE,
    },
  };
  public static readonly storageKeyValuePairMap = {
    [AppPlatform.ANDROID]: {
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_disk_space_free_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_SPACE_FREE_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_free_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SD_CARD_SPACE_FREE_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_disk_space_total_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISK_SPACE_TOTAL_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_total_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SD_CARD_SPACE_TOTAL_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
    },
    [AppPlatform.APPLE_IOS]: {
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_free_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SD_CARD_SPACE_FREE_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
      [COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_total_bytes]: {
        name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SD_CARD_SPACE_TOTAL_BYTES',
        dataType: DataDecorator.SIZE_BYTE,
      },
    },
  };
  public static readonly networkKeyValuePairMap = {
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_signal_strength]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.WIFI_SIGNAL_STRENGTH',
      dataType: DataDecorator.STRING_PERCENT,
    },
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_frequency]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.WIFI_NETWORK_FREQUENCY',
      dataType: DataDecorator.FREQ_MHZ,
    },
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_signal_strength]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MOBILE_SIGNAL_STRENGTH',
      dataType: DataDecorator.INTEGER,
    },
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_network_latency]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LATENCY',
      dataType: DataDecorator.TIME_DURATION,
    },
    [COLUMN_NAMES.byFullyQualifiedName.apteligent_network_jitter]: {
      name: 'STANDARD_DASHBOARD.DEVICES_DETAILS.JITTER',
      dataType: DataDecorator.TIME_DURATION,
    },
  };
  public static readonly thresholdNames = {
    OS_CRASHES: 'OS Crashes',
    BOOT_TIME: 'Boot Time',
    SHUTDOWN_TIME: 'Shutdown Time',
    BATTERY_HEALTH: 'Battery Health',
    CPU_HEALTH: 'CPU Health',
    GPU_HEALTH: 'GPU Health',
    MEMORY_HEALTH: 'Memory Health',
    WIFI_STRENGTH: 'Wifi Strength',
    DOWNLOAD_SPEED: 'Download Speed',
    UPLOAD_SPEED: 'Upload Speed',
  };
  // Desktop Columns
  public static readonly bootColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_pnp_load_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PNP_LOAD_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_main_pathload_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MAIN_PATH_LOAD_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_perf_degradation,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PERFORMANCE_DEGRADATION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOAD_DEGRADATION_TIME_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_load_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGON_LOAD_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_waiting_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGON_WAITING_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
  ];
  public static readonly shutdownColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_perf_degradation,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PERFORMANCE_DEGRADATION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOAD_DEGRADATION_TIME_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_system_session_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SYSTEM_SESSION_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_services_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SERVICE_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user_session_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER_SESSION_DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
  ];
  public static readonly unexpectedShutdownColumn: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REASON',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly patchColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_title,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.TITLE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_revision_number,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REVISION_NUMBER',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DURATION',
      dataUnit: DataUnit.MILLISECONDS,
    }),
  ];
  public static readonly networkDisconnectColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.NIC_TYPE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.NIC_DESCRIPTION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PHYSICAL_ADDRESS',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly networkConnectColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_ssid,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SSID',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_wifi_signal_range,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SIGNAL_STRENGTH',
      dataType: DataType[DataType.STRING],
    }),
    ...DevicesDashboardConfig.networkDisconnectColumns,
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_mode,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MODE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_rssi,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.RSSI',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_frequency,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.FREQUENCY',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_addresses,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.IPV4_ADDRESS',
      dataType: DataType[DataType.STRINGLIST],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv6_addresses,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.IPV6_ADDRESS',
      dataType: DataType[DataType.STRINGLIST],
    }),
  ];
  public static readonly appCrashColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APPLICATION_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_code,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EXCEPTION_CODE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_offset,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EXCEPTION_OFFSET',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly appHangColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APPLICATION_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly appUnresponsiveColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EXECUTABLE_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.UNRESPONSIVE_DURATION',
      dataUnit: DataUnit.MILLISECONDS,
    }),
  ];
  public static readonly appBootDegradationColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EXECUTABLE_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_duration_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DURATION_IN_MILLIS',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_load_degradation_time_millis,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEGRADATION',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_vendor,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VENDOR',
      dataType: DataType[DataType.STRING],
    }),
  ];
  // Mobile Columns
  public static readonly userFlowColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER_FLOW_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_state_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER_FLOW_STATE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_user_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileAndroidAppCrashColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_type,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR_TYPE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REASON',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileIosAppCrashColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_type,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR_TYPE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REASON',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileAppLoadColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_device_platform,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PLATFORM',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_system_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SYSTEM_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileAppInstallsColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_enrollment_user_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USERNAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_install_status_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_INSTALL_REASON',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileAndroidHandledExceptionsColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REASON',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileIosHandledExceptionsColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_reason,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.REASON',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_config_app_name,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileNetworkErrorColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_timestamp,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_net_error_msg,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url_host,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.URL_HOST',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_http_method,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.HTTP_METHOD',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.URL',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_app_version,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly zoomEventColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_start_time,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.START_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_uuid,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEETING_ID',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEETING_SCORE',
      dataType: DataType[DataType.STRING],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality_score,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.QUALITY_SCORE',
      dataType: DataType[DataType.DOUBLE],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_duration,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DURATION',
      dataType: DataType[DataType.INTEGER],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.zoom_qos__qos_type,
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.CONTRIBUTOR',
      dataType: DataType[DataType.STRING],
    }),
  ];
  public static readonly mobileNetworkEventColumns: Column[] = [];

  public static readonly deviceEvents: Record<string, string> = {
    // Desktop
    APPLICATION_CRASH: 'Application Crash',
    APPLICATION_HANG: 'Application Hang',
    APPLICATION_FOREGROUND: 'Application Foreground',
    APPLICATION_CHANGE: 'Application Change',
    APPLICATION_UNRESPONSIVE: 'Application Unresponsive',
    BOOT: 'Boot',
    BOOT_DEGRADATION: 'Boot Degradation',
    LOGON: 'Logon',
    LOGOUT: 'Logout',
    NETWORK_CONNECTED: 'Network Connected',
    NETWORK_DISCONNECTED: 'Network Disconnected',
    NETWORK_RECONFIGURED: 'Network Reconfigured',
    PATCH_INSTALL: 'Patch Install',
    PATCH_UNINSTALL: 'Patch Uninstall',
    SERVICE_INSTALL: 'Service Install',
    SERVICE_REMOVED: 'Service Removed',
    SLEEP: 'Sleep',
    SYSTEM_CRASH: 'System Crash',
    SHUTDOWN: 'Shutdown',
    UNEXPECTED_SHUTDOWN: 'Unexpected Shutdown',
    UNLOCK: 'Unlock',
    WAKE: 'Wake',
    ZOOM_MEETINGS: 'Zoom Meetings',
    // Mobile
    USER_FLOW_FAILURE: 'User Flow Failure',
    USER_FLOW_SUCCESS: 'User Flow Success',
    APP_CRASH: 'App Crash',
    APP_LOAD: 'App Load',
    APP_INSTALLS: 'App Installs',
    HANDLED_EXCEPTIONS: 'Handled Exceptions',
    NETWORK_ERRORS: 'Network Errors',
    NETWORK_EVENTS: 'Network Events',
    FAILED_LOGINS: 'Failed Logins',
    APP_LAUNCH: 'App Launch',
    TUNNEL_EVENTS: 'Tunnel Events',
  };

  // Device Events
  public static readonly userFlowRequestFields = [
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_event_timestamp,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_state_name,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_name,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_user_name,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_version,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_id,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_crash_id,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_device_uuid,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_platform,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_event_id,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_config_app_name,
  ];
  public static readonly deviceProfileSearchFieldsByEntityAndEvent = {
    [Entity.APPS]: {
      [this.deviceEvents.APPLICATION_CRASH]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_code,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_offset,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
      ],
      [this.deviceEvents.APPLICATION_HANG]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
      ],
      [this.deviceEvents.APPLICATION_UNRESPONSIVE]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
      ],
      [this.deviceEvents.APPLICATION_CHANGE]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_publisher,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_path,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
      ],
      [this.deviceEvents.BOOT_DEGRADATION]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_load_degradation_time_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_vendor,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
      ],
    },
    [Entity.DEVICES]: {
      [this.deviceEvents.BOOT]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_pnp_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_main_pathload_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_perf_degradation,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_waiting_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_system_session_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_services_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user_session_millis,
      ],
      [this.deviceEvents.SHUTDOWN]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_pnp_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_main_pathload_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_perf_degradation,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_waiting_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_system_session_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_services_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user_session_millis,
      ],
      [this.deviceEvents.SYSTEM_CRASH]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_parameters,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_stack_trace,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_crash_path,
      ],
      [this.deviceEvents.UNEXPECTED_SHUTDOWN]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_reason,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
      ],
    },
    [Entity.DEVICE_NET_EVENT]: {
      [this.deviceEvents.NETWORK_CONNECTED]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_ssid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_wifi_signal_range,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_mode,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_rssi,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_frequency,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_addresses,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv6_addresses,
      ],
      [this.deviceEvents.NETWORK_DISCONNECTED]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address,
      ],
      [this.deviceEvents.NETWORK_RECONFIGURED]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_ssid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_wifi_signal_range,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_mode,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_rssi,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_frequency,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_addresses,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv6_addresses,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_dns_servers,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_dhcp_server,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_wifi_generation,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_security_standard,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_auth_type,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_encryption,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_bss_wps_enabled,
      ],
    },
    [Entity.OS_UPDATES]: {
      [this.deviceEvents.PATCH_INSTALL]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_title,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_revision_number,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_duration_millis,
      ],
      [this.deviceEvents.PATCH_UNINSTALL]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_title,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_revision_number,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_duration_millis,
      ],
    },
    [Entity.USER_ACTIONS]: {
      [this.deviceEvents.LOGON]: [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_result,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_device_model,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_device_name,
      ],
    },
    [Entity.USER_FLOW]: {
      [this.deviceEvents.USER_FLOW_FAILURE]: this.userFlowRequestFields,
      [this.deviceEvents.USER_FLOW_SUCCESS]: this.userFlowRequestFields,
    },
    [Entity.CRASH_ANDROID]: {
      [this.deviceEvents.APP_CRASH]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_config_app_name,
      ],
    },
    [Entity.CRASH_IOS]: {
      [this.deviceEvents.APP_CRASH]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_type,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_config_app_name,
      ],
    },
    [Entity.APPLOAD]: {
      [this.deviceEvents.APP_LOAD]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_config_app_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_system_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_event_id,
      ],
    },
    [Entity.APPLICATION_CHANGE_EVENT]: {
      [this.deviceEvents.APP_INSTALLS]: [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_package_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_enrollment_user_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_install_status_reason,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_guid,
      ],
    },
    [Entity.HANDLED_EXCEPTION_ANDROID]: {
      [this.deviceEvents.HANDLED_EXCEPTIONS]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_config_app_name,
      ],
    },
    [Entity.HANDLED_EXCEPTION_IOS]: {
      [this.deviceEvents.HANDLED_EXCEPTIONS]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_config_app_name,
      ],
    },
    [Entity.NET_ERROR]: {
      [this.deviceEvents.NETWORK_ERRORS]: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_net_error_msg,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url_host,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_http_method,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_id,
      ],
    },
    [Entity.QUALITY_OF_SERVICE]: {
      [this.deviceEvents.ZOOM_MEETINGS]: [
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_start_time,
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_uuid,
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality,
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality_score,
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_duration,
        COLUMN_NAMES.byFullyQualifiedName.zoom_qos__qos_type,
      ],
    },
  };

  // Desktop Events
  public static readonly deviceTableColumnNamesByEvent: Record<string, Record<string, Column[]>> = {
    [AppPlatform.WINDOWS_DESKTOP]: {
      [this.deviceEvents.BOOT]: DevicesDashboardConfig.bootColumns,
      [this.deviceEvents.SHUTDOWN]: DevicesDashboardConfig.shutdownColumns,
      [this.deviceEvents.SYSTEM_CRASH]: [
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
          dataType: DataType[DataType.DATETIME],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ERROR',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESS',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MODULE',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_parameters,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.CRASH_PARAMETER_LIST',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
          dataType: DataType[DataType.STRING],
        }),
      ],
      [this.deviceEvents.UNEXPECTED_SHUTDOWN]: DevicesDashboardConfig.unexpectedShutdownColumn,
    },
    [AppPlatform.APPLE_MACOS]: {
      [this.deviceEvents.BOOT]: DevicesDashboardConfig.bootColumns,
      [this.deviceEvents.SHUTDOWN]: DevicesDashboardConfig.shutdownColumns,
      [this.deviceEvents.SYSTEM_CRASH]: [
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
          dataType: DataType[DataType.DATETIME],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESS',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_stack_trace,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.STACK_TRACE',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_crash_path,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.CRASH_PATH',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
          dataType: DataType[DataType.STRING],
        }),
      ],
      [this.deviceEvents.UNEXPECTED_SHUTDOWN]: DevicesDashboardConfig.unexpectedShutdownColumn,
    },
  };
  public static readonly appTableColumnNamesByEvent: Record<string, Record<string, Column[]>> = {
    [AppPlatform.WINDOWS_DESKTOP]: {
      [this.deviceEvents.APPLICATION_CRASH]: DevicesDashboardConfig.appCrashColumns,
      [this.deviceEvents.APPLICATION_HANG]: DevicesDashboardConfig.appHangColumns,
      [this.deviceEvents.APPLICATION_UNRESPONSIVE]: DevicesDashboardConfig.appUnresponsiveColumns,
      [this.deviceEvents.APPLICATION_CHANGE]: [
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
          dataType: DataType[DataType.DATETIME],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
          dataType: DataType[DataType.STRING],
        }),
      ],
      [this.deviceEvents.BOOT_DEGRADATION]: DevicesDashboardConfig.appBootDegradationColumns,
      [this.deviceEvents.ZOOM_MEETINGS]: DevicesDashboardConfig.zoomEventColumns,
    },
    [AppPlatform.APPLE_MACOS]: {
      [this.deviceEvents.APPLICATION_CRASH]: DevicesDashboardConfig.appCrashColumns,
      [this.deviceEvents.APPLICATION_HANG]: DevicesDashboardConfig.appHangColumns,
      [this.deviceEvents.APPLICATION_UNRESPONSIVE]: DevicesDashboardConfig.appUnresponsiveColumns,
      [this.deviceEvents.APPLICATION_CHANGE]: [
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
          dataType: DataType[DataType.DATETIME],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_name,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PACKAGE_NAME',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_publisher,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PACKAGE_PUBLISHER',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_path,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_INSTALL_PATH',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_VERSION',
          dataType: DataType[DataType.STRING],
        }),
        new Column({
          name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
          label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_VERSION',
          dataType: DataType[DataType.STRING],
        }),
      ],
      [this.deviceEvents.BOOT_DEGRADATION]: DevicesDashboardConfig.appBootDegradationColumns,
      [this.deviceEvents.ZOOM_MEETINGS]: DevicesDashboardConfig.zoomEventColumns,
    },
  };
  public static readonly osUpdateTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.PATCH_INSTALL]: DevicesDashboardConfig.patchColumns,
    [this.deviceEvents.PATCH_UNINSTALL]: DevicesDashboardConfig.patchColumns,
  };
  public static readonly networkTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.NETWORK_CONNECTED]: DevicesDashboardConfig.networkConnectColumns,
    [this.deviceEvents.NETWORK_DISCONNECTED]: [
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
        dataType: DataType[DataType.DATETIME],
      }),
      ...DevicesDashboardConfig.networkDisconnectColumns,
    ],
    [this.deviceEvents.NETWORK_RECONFIGURED]: [
      ...DevicesDashboardConfig.networkConnectColumns,
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_dns_servers,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DNS_SERVER_ADDRESS',
        dataType: DataType[DataType.STRINGLIST],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_ipv4_dhcp_server,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DHCP_SERVER_ADDRESS',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_wifi_generation,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.WIFI_GENERATION',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_security_standard,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SECURITY_STANDARD',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_auth_type,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AUTH_TYPE',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_encryption,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.ENCRYPTION',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_bss_wps_enabled,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.WPS_ENABLED',
        dataType: DataType[DataType.BOOLEAN],
      }),
    ],
  };
  public static readonly logonTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.LOGON]: [
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.EVENT_TIME',
        dataType: DataType[DataType.DATETIME],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_result,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.RESULT',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.LOGON_TYPE',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_device_model,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_MODEL',
        dataType: DataType[DataType.STRING],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_device_name,
        label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_NAME',
        dataType: DataType[DataType.STRING],
      }),
    ],
  };
  public static readonly zoomEventColumnsByEvent: Record<string, Column[]> = {
    [this.deviceEvents.ZOOM_MEETINGS]: this.zoomEventColumns,
  };

  // Mobile Events
  public static readonly userFlowTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.USER_FLOW_FAILURE]: DevicesDashboardConfig.userFlowColumns,
    [this.deviceEvents.USER_FLOW_SUCCESS]: DevicesDashboardConfig.userFlowColumns,
  };
  public static readonly appEventsTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.APP_LOAD]: DevicesDashboardConfig.mobileAppLoadColumns,
    [this.deviceEvents.APP_INSTALLS]: DevicesDashboardConfig.mobileAppInstallsColumns,
  };
  public static readonly appEventsTableColumnNamesByAndroidEvent: Record<string, Column[]> = {
    [this.deviceEvents.APP_CRASH]: DevicesDashboardConfig.mobileAndroidAppCrashColumns,
    [this.deviceEvents.HANDLED_EXCEPTIONS]: DevicesDashboardConfig.mobileAndroidHandledExceptionsColumns,
  };
  public static readonly appEventsTableColumnNamesByIosEvent: Record<string, Column[]> = {
    [this.deviceEvents.APP_CRASH]: DevicesDashboardConfig.mobileIosAppCrashColumns,
    [this.deviceEvents.HANDLED_EXCEPTIONS]: DevicesDashboardConfig.mobileIosHandledExceptionsColumns,
  };
  public static readonly mobileNetworkTableColumnNamesByEvent: Record<string, Column[]> = {
    [this.deviceEvents.NETWORK_ERRORS]: DevicesDashboardConfig.mobileNetworkErrorColumns,
    [this.deviceEvents.NETWORK_EVENTS]: DevicesDashboardConfig.mobileNetworkEventColumns,
  };

  // Events Map
  public static readonly desktopDeviceEventsMaps = {
    1: Entity.DEVICES,
    2: Entity.APPS,
    3: Entity.USER_ACTIONS,
    4: Entity.NET_EVENT,
    5: Entity.OS_UPDATES,
    6: Entity.QUALITY_OF_SERVICE,
  };
  public static readonly mobileDeviceEventsMaps = {
    [this.deviceEvents.USER_FLOW_SUCCESS]: {
      [AppPlatform.ANDROID]: Entity.USER_FLOW,
      [AppPlatform.APPLE_IOS]: Entity.USER_FLOW,
    },
    [this.deviceEvents.USER_FLOW_FAILURE]: {
      [AppPlatform.ANDROID]: Entity.USER_FLOW,
      [AppPlatform.APPLE_IOS]: Entity.USER_FLOW,
    },
    [this.deviceEvents.APP_CRASH]: {
      [AppPlatform.ANDROID]: Entity.CRASH_ANDROID,
      [AppPlatform.APPLE_IOS]: Entity.CRASH_IOS,
    },
    [this.deviceEvents.APP_LOAD]: {
      [AppPlatform.ANDROID]: Entity.APPLOAD,
      [AppPlatform.APPLE_IOS]: Entity.APPLOAD,
    },
    [this.deviceEvents.APP_INSTALLS]: {
      [AppPlatform.ANDROID]: Entity.APPLICATION_CHANGE_EVENT,
      [AppPlatform.APPLE_IOS]: Entity.APPLICATION_CHANGE_EVENT,
    },
    [this.deviceEvents.HANDLED_EXCEPTIONS]: {
      [AppPlatform.ANDROID]: Entity.HANDLED_EXCEPTION_ANDROID,
      [AppPlatform.APPLE_IOS]: Entity.HANDLED_EXCEPTION_IOS,
    },
    [this.deviceEvents.NETWORK_ERRORS]: {
      [AppPlatform.ANDROID]: Entity.NET_ERROR,
      [AppPlatform.APPLE_IOS]: Entity.NET_ERROR,
    },
    [this.deviceEvents.ZOOM_MEETINGS]: {
      [AppPlatform.ANDROID]: Entity.QUALITY_OF_SERVICE,
      [AppPlatform.APPLE_IOS]: Entity.QUALITY_OF_SERVICE,
    },
  };
  public static readonly referenceLineType = {
    AVG: 'avg',
    TEMPERATURE: 'temperature',
    TIME: 'time',
    VOLTAGE: 'voltage',
  };
  public static readonly gpu0SeriesName = 'GPU 0';
  public static readonly gpu1SeriesName = 'GPU 1';
  public static readonly gpu2SeriesName = 'GPU 2';
  public static readonly eventListTimeSpan = 1000 * 60 * 30;
  public static readonly appErrorLinkPath = `/${ROUTE_NAMES.DASHBOARD.APP_DEEM_DETAILS}/overview`;
  public static readonly unknownColorSet = {
    name: 'UNKNOWN_VALUE',
    color: 'gray',
  };
  public static readonly deviceLinkKeys = {
    ids: [
      COLUMN_NAMES.byName._airwatch_device_guid,
      COLUMN_NAMES.byName._device_guid,
      COLUMN_NAMES.byName.device_guid,
      COLUMN_NAMES.byName.device_uuid,
    ],
    names: [
      COLUMN_NAMES.byName._airwatch_device__device_friendly_name,
      COLUMN_NAMES.byName._device_friendly_name,
      COLUMN_NAMES.byName._device_name,
      COLUMN_NAMES.byName.device_friendly_name,
      COLUMN_NAMES.byName.device_name,
    ],
    platforms: [COLUMN_NAMES.byName._device_platform, COLUMN_NAMES.byName.device_platform, COLUMN_NAMES.byName.platform],
    models: [COLUMN_NAMES.byName._device_model, COLUMN_NAMES.byName.device_model, COLUMN_NAMES.byName.model],
  };
  public static readonly MAX_LIST_CONDITION = 1024;
  public static readonly DEVICE_THRESHOLD_COLUMN_MAP = {
    [RawThresholdAttribute.CRASH_COUNT]: {} as TableColumnValue,
    [RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS]: {} as TableColumnValue,
    [RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS]: {} as TableColumnValue,
    [RawThresholdAttribute.BATTERY_HEALTH]: {} as TableColumnValue,
    [RawThresholdAttribute.CPU_HEALTH]: {} as TableColumnValue,
    [RawThresholdAttribute.GPU_HEALTH]: {} as TableColumnValue,
    [RawThresholdAttribute.MEMORY_HEALTH]: {} as TableColumnValue,
    [RawThresholdAttribute.WIFI_STRENGTH]: {} as TableColumnValue,
    [RawThresholdAttribute.DOWNLOAD_SPEED]: {} as TableColumnValue,
    [RawThresholdAttribute.UPLOAD_SPEED]: {} as TableColumnValue,
  };
  public static readonly SCORE_BREAKDOWN_DEFAULTS = 5;
  public static readonly SCORE_BREAKDOWN_ADDITIONS = 3;
  public static readonly mergedScoreSessionName = COLUMN_NAMES.byName.session;
  public static readonly widgetSubtypeByPerformanceType = {
    [RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS]: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_DEVICES,
    [RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS]: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_DEVICES,
    [RawThresholdAttribute.BATTERY_HEALTH]: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_DEVICES,
    [RawThresholdAttribute.CPU_HEALTH]: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_DEVICES,
    [RawThresholdAttribute.CRASH_COUNT]: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_DEVICES,
    [RawThresholdAttribute.DEVICE_TEMPERATURE]: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_DEVICES,
    [RawThresholdAttribute.GPU_HEALTH]: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_DEVICES,
    [RawThresholdAttribute.MEMORY_HEALTH]: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_DEVICES,
  };
  public static readonly colors = DashboardColors;
  public static readonly COMPARE_COLORS = [
    this.colors.orange,
    this.colors.babyBlue,
    this.colors.purple,
    this.colors.yellow,
    this.colors.pink,
  ];
  public static readonly performanceType: Record<string, string> = {
    CPU: 'CPU',
    GPU0: 'GPU0',
    GPU1: 'GPU1',
    GPU2: 'GPU2',
    MEMORY: 'MEMORY',
    SIGNAL: 'SIGNAL',
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD',
  };
  public static readonly appPerformanceCustomColors = [
    {
      name: this.performanceType.CPU,
      value: this.colors.navyBlue,
    },
    {
      name: this.performanceType.GPU0,
      value: this.colors.red,
    },
    {
      name: this.performanceType.GPU1,
      value: this.colors.pink,
    },
    {
      name: this.performanceType.GPU2,
      value: this.colors.yellow,
    },
    {
      name: this.performanceType.MEMORY,
      value: this.colors.orange,
    },
  ];
  public static readonly destPerformanceCustomColors = [
    {
      name: 'Total Negotiation',
      value: this.colors.babyBlue,
    },
    {
      name: 'Redirect',
      value: this.colors.red,
    },
    {
      name: 'DNS Lookup',
      value: this.colors.purple,
    },
    {
      name: 'Connection Setup',
      value: this.colors.yellow,
    },
    {
      name: 'TLS Negotiation',
      value: this.colors.pink,
    },
    {
      name: 'HTTP Request',
      value: this.colors.green,
    },
    {
      name: 'HTTP Response',
      value: this.colors.navyBlue,
    },
    {
      name: 'Render',
      value: this.colors.orange,
    },
  ];
  public static readonly deviceEventsTypes: string[] = [
    // Desktop
    this.deviceEvents.BOOT,
    this.deviceEvents.SHUTDOWN,
    this.deviceEvents.SYSTEM_CRASH,
    this.deviceEvents.UNEXPECTED_SHUTDOWN,
    this.deviceEvents.APPLICATION_CHANGE,
    this.deviceEvents.APPLICATION_CRASH,
    this.deviceEvents.APPLICATION_HANG,
    this.deviceEvents.APPLICATION_UNRESPONSIVE,
    this.deviceEvents.BOOT_DEGRADATION,
    this.deviceEvents.LOGON,
    this.deviceEvents.NETWORK_CONNECTED,
    this.deviceEvents.NETWORK_DISCONNECTED,
    this.deviceEvents.NETWORK_RECONFIGURED,
    this.deviceEvents.PATCH_INSTALL,
    this.deviceEvents.PATCH_UNINSTALL,
    // Mobile
    this.deviceEvents.USER_FLOW_FAILURE,
    this.deviceEvents.USER_FLOW_SUCCESS,
    this.deviceEvents.APP_CRASH,
    this.deviceEvents.APP_LOAD,
    this.deviceEvents.APP_INSTALLS,
    this.deviceEvents.HANDLED_EXCEPTIONS,
    this.deviceEvents.NETWORK_ERRORS,
    this.deviceEvents.NETWORK_EVENTS,
    this.deviceEvents.FAILED_LOGINS,
    this.deviceEvents.APP_LAUNCH,
    this.deviceEvents.TUNNEL_EVENTS,
  ];
  public static readonly deviceBubbleEventsTypes: string[] = [
    this.deviceEvents.LOGOUT,
    this.deviceEvents.SERVICE_INSTALL,
    this.deviceEvents.SERVICE_REMOVED,
    this.deviceEvents.SLEEP,
    this.deviceEvents.UNLOCK,
    this.deviceEvents.WAKE,
  ];
  public static readonly eventStatusNames = {
    CRASH: 'Crash',
    SUCCESS: 'Success',
    TIMEOUT: 'Timeout',
    USER_DEFINED_FAILURE: 'User-defined Failure',
  };
  public static readonly userFlowBreadCrumbKeyNames = {
    APP_ID: 'app_id',
    APP_PACKAGE_ID: '_app_package_id',
    AIRWATCH_DEVICE_GUID: '_airwatch_device_guid',
    DEVICE_GUID: 'device_guid',
    EVENT_ID: 'event_id',
    EVENT_TIMESTAMP: 'event_timestamp',
    PLATFORM: 'platform',
  };

  // Mobile Properties
  public static readonly uemDeviceProperties = [
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_user_name,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_date,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_shared_device,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_mac_address,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_apple_time_zone,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_corp_liable,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_guid,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_available_physical_memory,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_total_physical_memory,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_available_capacity,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_capacity,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_full_charge_capacity,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_charge_cycle,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_manufacturer_date,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_serial_number,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_encryption_status,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_lost_mode_enabled,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_safe_mode,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_user_guid,
  ];
  public static readonly sdkDeviceProperties = [
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_service_enabled_int,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_latitude,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_longitude,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_process_cpu_time,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_cores_logical,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_cores_physical,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_utilization,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_manufacturer,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_memory_total_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_memory_allocated_per_app_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_disk_space_total_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_disk_space_free_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_total_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sdcard_space_free_bytes,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_last_boot_time,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_device_platform,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_screen_brightness,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sleep_duration,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_device_wake_duration,
  ];
  public static readonly sdkDeviceBatteryProperties = [
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_rate,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_status,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_level,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_temperature,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_type,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_voltage,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_plugged_type,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_status,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_unplugged,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_charging,
  ];
  public static readonly sdkDeviceNetworkProperties = [
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_network_connectivity_type,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_internet_available,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_is_multicast,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_is_roaming,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_network_type,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_radio_active,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_signal_strength,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_network_download_speed_mbps,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_network_upload_speed_mbps,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_vpn_connected,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_configuration_status,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_on,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_frequency,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ssid,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_bssid,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_latency,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_jitter,
    COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ip_address,
  ];
  // Mobile Properties - Device Info
  public static readonly propertiesDeviceInfoMap = {
    [AppPlatform.ANDROID]: {
      [DeviceInfoType.ENROLLED_USER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_user_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.PROCESS_CPU_TIME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_process_cpu_time,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.ENROLLMENT_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.LOGICAL_CPU_CORES]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_cores_logical,
          type: DataDecorator.INTEGER,
        },
      ],
      [DeviceInfoType.DEVICE_FRIENDLY_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.PHYSICAL_CPU_CORES]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_cores_physical,
          type: DataDecorator.INTEGER,
        },
      ],
      [DeviceInfoType.OS_VERSION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TOTAL_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_total_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.MODEL]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.AVAILABLE_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_available_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TOTAL_DISK_SPACE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_capacity,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.MANUFACTURER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_manufacturer,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.AVAILABLE_DISK_SPACE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_available_capacity,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.OWNERSHIP]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_corp_liable,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TIMEZONE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_apple_time_zone,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.SHARED_DEVICE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_shared_device,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.MAC_ADDRESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_mac_address,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.LOCATION_GROUP_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.PUBLIC_IP_ADDRESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ip_address,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.LOCATION_GROUP_GUID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_guid,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.DEVICE_TAGS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
          type: DataDecorator.STRING_LIST,
        },
      ],
    },
    [AppPlatform.APPLE_IOS]: {
      [DeviceInfoType.ENROLLED_USER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_user_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.CPU_USAGE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_utilization,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.ENROLLMENT_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TOTAL_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_total_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.DEVICE_FRIENDLY_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.AVAILABLE_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_available_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [DeviceInfoType.OS_VERSION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MODEL]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TIMEZONE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_apple_time_zone,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MANUFACTURER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_manufacturer,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MAC_ADDRESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_mac_address,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.OWNERSHIP]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_corp_liable,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.PUBLIC_IP_ADDRESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ip_address,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.SHARED_DEVICE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_shared_device,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.DEVICE_TAGS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
          type: DataDecorator.STRING_LIST,
        },
      ],
      [DeviceInfoType.LOCATION_GROUP_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.LOCATION_GROUP_GUID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_guid,
          type: DataDecorator.STRING,
        },
      ],
    },
  };
  public static readonly userPropertiesMap = {
    [EdiType.EMPLOYEE_NAME]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_full_name,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.EMPLOYEE_ID]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_employee_id,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_EMAIL]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_email,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_GROUPS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_groups,
        type: DataDecorator.STRING_LIST,
      },
    ],
    [EdiType.USER_STATUS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_status,
        type: DataDecorator.ACTIVE_INACTIVE,
      },
    ],
    [EdiType.DEPARTMENT]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_department,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_CUSTOM_ATTR_1]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_custom_attribute_1,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.DOMAIN]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_domain,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_CUSTOM_ATTR_2]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_custom_attribute_2,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.COST_CENTER]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_cost_center,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_CUSTOM_ATTR_3]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_custom_attribute_3,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.MANAGER]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_manager_full_name,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_CUSTOM_ATTR_4]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_custom_attribute_4,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.MANAGER_EMAIL]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_manager_email_address,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.USER_CUSTOM_ATTR_5]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_custom_attribute_5,
        type: DataDecorator.STRING,
      },
    ],
  };
  public static readonly ediDesktopDeviceInfoMap = {
    [EdiType.PROCESSOR]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_processor,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.MEMORY]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_physical_memory_bytes,
        type: DataDecorator.SIZE_BYTE,
      },
    ],
    [EdiType.SYSTEM]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_system_type,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.BIOS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_version,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.FRIENDLY_VERSION_NAME]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_device_os_version_friendly_name,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.BIOS_DATE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_timestamp,
        type: DataDecorator.DATETIME,
      },
    ],
    [EdiType.OS_VERSION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_os_version,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.TIMEZONE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_time_zone,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.DEEM_AGENT_VERSION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_agent_version,
        type: DataDecorator.STRING,
      },
    ],
  };
  public static readonly ediMobileDeviceInfoMap = {
    [AppPlatform.ANDROID]: {
      [EdiType.FRIENDLY_VERSION_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_device_platform,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.ENROLLMENT_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.OS_VERSION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.ENROLLMENT_DATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_date,
          type: DataDecorator.DATETIME,
        },
      ],
      [EdiType.DEVICE_SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.TOTAL_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_total_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [EdiType.DEVICE_TAGS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
          type: DataDecorator.STRING_LIST,
        },
      ],
      [EdiType.TIMEZONE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_apple_time_zone,
          type: DataDecorator.STRING,
        },
      ],
    },
    [AppPlatform.APPLE_IOS]: {
      [EdiType.FRIENDLY_VERSION_NAME]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_device_platform,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.ENROLLMENT_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.OS_VERSION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.ENROLLMENT_DATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_date,
          type: DataDecorator.DATETIME,
        },
      ],
      [EdiType.CPU_USAGE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_cpu_utilization,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [EdiType.DEVICE_SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
          type: DataDecorator.STRING,
        },
      ],
      [EdiType.TOTAL_PHYSICAL_MEMORY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_total_physical_memory,
          type: DataDecorator.SIZE_BYTE,
        },
      ],
      [EdiType.DEVICE_TAGS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
          type: DataDecorator.STRING_LIST,
        },
      ],
      [EdiType.TIMEZONE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_apple_time_zone,
          type: DataDecorator.STRING,
        },
      ],
    },
  };
  public static readonly deviceInfoMap = {
    [EdiType.ENROLLED_USER]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_user_name,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.ENROLLMENT_STATUS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.DEVICE_FRIENDLY_NAME]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.OS_VERSION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.DEVICE_MAKE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.DEVICE_TAGS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
        type: DataDecorator.STRING_LIST,
      },
    ],
    [EdiType.DEVICE_SERIAL_NUMBER]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,
        type: DataDecorator.STRING,
      },
    ],
  };
  public static readonly deviceSecurityMap = {
    [EdiType.COMPROMISED]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_compromised,
        type: DataDecorator.YES_NO,
      },
    ],
    [EdiType.BITLOCKER_ENCRYPTION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_windows_bitlocker_status,
        type: DataDecorator.YES_NO,
      },
    ],
    [EdiType.RECOVERY_KEY_ESCROWED]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_recovery_key_escrowed,
        type: DataDecorator.YES_NO,
      },
    ],
    [EdiType.ENCRYPTION_METHOD]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_windows_bitlocker_encryption_method,
        type: DataDecorator.STRING,
      },
    ],
    [EdiType.TPM_ENABLED]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tpm_chip,
        type: DataDecorator.YES_NO,
      },
    ],
  };
  // Mobile Properties - History Info
  public static readonly propertiesHistoryInfoMap = {
    [DeviceInfoType.LAST_BOOT_DATE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_last_boot_time,
        type: DataDecorator.DATETIME,
      },
    ],
    [DeviceInfoType.TIME_SINCE_LAST_BOOT]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_last_boot_time,
        type: DataDecorator.TIME_DURATION_FROM_NOW,
      },
    ],
    [DeviceInfoType.ENROLLMENT_DATE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_date,
        type: DataDecorator.DATETIME,
      },
    ],
    [DeviceInfoType.SLEEP_DURATION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_sleep_duration,
        type: DataDecorator.TIME_DURATION,
      },
    ],
    [DeviceInfoType.ENCRYPTION_STATUS]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_encryption_status,
        type: DataDecorator.STRING,
      },
    ],
    [DeviceInfoType.WAKE_DURATION]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_wake_duration,
        type: DataDecorator.TIME_DURATION,
      },
    ],
    [DeviceInfoType.SAFE_MODE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_safe_mode,
        type: DataDecorator.YES_NO,
      },
    ],
    [DeviceInfoType.LOST_MODE]: [
      {
        attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_lost_mode_enabled,
        type: DataDecorator.YES_NO,
      },
    ],
  };
  // Mobile Properties - Network Info
  public static readonly propertiesNetworkInfoMap = {
    [AppPlatform.ANDROID]: {
      [DeviceInfoType.NETWORK_CONNECTION_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_network_connectivity_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MOBILE_SIGNAL_STRENGTH]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_signal_strength,
          type: DataDecorator.INTEGER,
        },
      ],
      [DeviceInfoType.WIFI_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_on,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.MOBILE_NETWORK_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_network_type,
          type: DataDecorator.STRING_LIST,
        },
      ],
      [DeviceInfoType.SSID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ssid,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MOBILE_RADIO_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_radio_active,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.BSSID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_bssid,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.LATENCY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_latency,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.WIFI_FREQUENCY]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_frequency,
          type: DataDecorator.FREQ_MHZ,
        },
      ],
      [DeviceInfoType.JITTER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_jitter,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.WIFI_CONFIGURATION_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_configuration_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.ROAMING_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_is_roaming,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.MULTICAST]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_is_multicast,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.VPN_CONNECTION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_vpn_connected,
          type: DataDecorator.YES_NO,
        },
      ],
    },
    [AppPlatform.APPLE_IOS]: {
      [DeviceInfoType.NETWORK_CONNECTION_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_network_connectivity_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MOBILE_NETWORK_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_mobile_network_type,
          type: DataDecorator.STRING_LIST,
        },
      ],
      [DeviceInfoType.WIFI_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_wifi_on,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.SSID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_ssid,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.BSSID]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_bssid,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.MULTICAST]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_is_multicast,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.VPN_CONNECTION]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_vpn_connected,
          type: DataDecorator.YES_NO,
        },
      ],
    },
  };
  // Mobile Properties - Battery Info
  public static readonly propertiesBatteryInfoMap = {
    [AppPlatform.ANDROID]: {
      [DeviceInfoType.BATTERY_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.BATTERY_CHARGING_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.BATTERY_LEVEL]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_level,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.BATTERY_CHARGING_RATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_rate,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.BATTERY_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TIME_SPENT_CHARGING]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_charging,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.BATTERY_VOLTAGE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_voltage,
          type: DataDecorator.VOLTAGE,
        },
      ],
      [DeviceInfoType.BATTERY_PLUGGED_IN_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_plugged_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.BATTERY_TEMPERATURE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_temperature,
          type: DataDecorator.TEMPERATURE_C,
        },
      ],
      [DeviceInfoType.TIME_SINCE_UNPLUGGED]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_unplugged,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.MANUFACTURER_DATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_manufacturer_date,
          type: DataDecorator.DATETIME,
        },
      ],
      [DeviceInfoType.BATTERY_CHARGE_CYCLE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_charge_cycle,
          type: DataDecorator.INTEGER,
        },
      ],
      [DeviceInfoType.SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_serial_number,
          type: DataDecorator.STRING,
        },
      ],
    },
    [AppPlatform.APPLE_IOS]: {
      [DeviceInfoType.BATTERY_CHARGING_STATUS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_status,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.BATTERY_LEVEL]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_level,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.BATTERY_CHARGING_RATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_charging_rate,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.BATTERY_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TIME_SPENT_CHARGING]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_charging,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.BATTERY_PLUGGED_IN_TYPE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_plugged_type,
          type: DataDecorator.STRING,
        },
      ],
      [DeviceInfoType.TIME_SINCE_UNPLUGGED]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_battery_time_since_unplugged,
          type: DataDecorator.TIME_DURATION,
        },
      ],
      [DeviceInfoType.MANUFACTURER_DATE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_manufacturer_date,
          type: DataDecorator.DATETIME,
        },
      ],
      [DeviceInfoType.BATTERY_CHARGE_CYCLE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_charge_cycle,
          type: DataDecorator.INTEGER,
        },
      ],
      [DeviceInfoType.SERIAL_NUMBER]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_battery_serial_number,
          type: DataDecorator.STRING,
        },
      ],
    },
  };
  // Mobile Properties - Preferences Info
  public static readonly propertiesPreferencesInfoMap = {
    [AppPlatform.ANDROID]: {
      [DeviceInfoType.LOCATION_SERVICE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_service_enabled_int,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.LATITUDE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_latitude,
          type: DataDecorator.STRING_INTEGER,
        },
      ],
      [DeviceInfoType.SCREEN_BRIGHTNESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_screen_brightness,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
      [DeviceInfoType.LONGITUDE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_longitude,
          type: DataDecorator.STRING_INTEGER,
        },
      ],
    },
    [AppPlatform.APPLE_IOS]: {
      [DeviceInfoType.LOCATION_SERVICE]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_location_service_enabled_int,
          type: DataDecorator.YES_NO,
        },
      ],
      [DeviceInfoType.SCREEN_BRIGHTNESS]: [
        {
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_screen_brightness,
          type: DataDecorator.STRING_PERCENT,
        },
      ],
    },
  };

  public static readonly TIMELINE_EVENTS_COLOR_SCHEMA = {
    //desktop
    [this.deviceEvents.BOOT]: '#089de2',
    [this.deviceEvents.SHUTDOWN]: '#4cb9ea',
    [this.deviceEvents.SYSTEM_CRASH]: '#f38b00',
    [this.deviceEvents.UNEXPECTED_SHUTDOWN]: '#007e7a',
    [this.deviceEvents.APPLICATION_CHANGE]: '#004a70',
    [this.deviceEvents.APPLICATION_CRASH]: '#027dc0',
    [this.deviceEvents.APPLICATION_HANG]: '#006668',
    [this.deviceEvents.APPLICATION_UNRESPONSIVE]: '#9460b8',
    [this.deviceEvents.BOOT_DEGRADATION]: '#919fa8',
    [this.deviceEvents.LOGON]: '#00bfa9',
    [this.deviceEvents.NETWORK_CONNECTED]: '#62a420',
    [this.deviceEvents.NETWORK_DISCONNECTED]: '#ffdc0b',
    [this.deviceEvents.NETWORK_RECONFIGURED]: '#c7e59c',
    [this.deviceEvents.PATCH_INSTALL]: '#314351',
    [this.deviceEvents.PATCH_UNINSTALL]: '#dff0d0',
    [this.deviceEvents.LOGOUT]: '#026cac',
    [this.deviceEvents.SERVICE_INSTALL]: '#60b515',
    [this.deviceEvents.SERVICE_REMOVED]: '#068fd4',
    [this.deviceEvents.SLEEP]: '#29abe7',
    [this.deviceEvents.UNLOCK]: '#efd603',
    [this.deviceEvents.WAKE]: '#00968b',
    //mobile
    [this.deviceEvents.USER_FLOW_FAILURE]: '#089de2',
    [this.deviceEvents.USER_FLOW_SUCCESS]: '#4cb9ea',
    [this.deviceEvents.APP_CRASH]: '#f38b00',
    [this.deviceEvents.APP_LOAD]: '#007e7a',
    [this.deviceEvents.APP_INSTALLS]: '#004a70',
    [this.deviceEvents.HANDLED_EXCEPTIONS]: '#027dc0',
    [this.deviceEvents.NETWORK_ERRORS]: '#006668',
    [this.deviceEvents.NETWORK_EVENTS]: '#9460b8',
    [this.deviceEvents.FAILED_LOGINS]: '#919fa8',
    [this.deviceEvents.APP_LAUNCH]: '#00bfa9',
    [this.deviceEvents.TUNNEL_EVENTS]: '#62a420',
  };

  /**
   * trackByEventType
   * @param {number} _index
   * @param {GenericObject} item
   * @returns {string}
   * @memberOf DevicesDashboardConfig
   */
  public static trackByEventType(_index: number, item: GenericObject): string {
    return item.eventType;
  }
}
