/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AutomationSourceObject } from '@ws1c/intelligence-models/automation';
import { dateFormatConverter, enumConverterFactory, filterConditionConverter } from '@ws1c/intelligence-models/converters';
import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { QueryBuilder, RuleGroup } from '@ws1c/intelligence-models/filter';
import { SourceObjectType } from '@ws1c/intelligence-models/source-object-type.enum';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils';
import { DeemIncidentStatus } from './deem-incident-status.enum';
import { DeemPlaybookStep } from './deem-playbook-step.model';

/**
 * DeemIncident
 * @export
 * @class DeemIncident
 */
@Serializable
export class DeemIncident extends UserTouchDetails {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: filterConditionConverter, excludeToJson: true })
  public filterCondition: any = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty('incident_key')
  public incidentKey: string = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(DeemIncidentStatus) })
  public status: DeemIncidentStatus = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('target_entity')
  public targetEntity: string = undefined;

  @JsonProperty({ name: 'date_range', cls: TrendDateRange })
  public dateRange: TrendDateRange = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('source_object_id')
  public sourceObjectId: string = undefined;

  @JsonProperty({ name: 'source_object_type', customConverter: enumConverterFactory(SourceObjectType) })
  public sourceObjectType: SourceObjectType = undefined;

  @JsonProperty('playbook_id')
  public playbookId: string = undefined;

  @JsonProperty('playbook_version_id')
  public playbookVersionId: string = undefined;

  @JsonProperty('playbook_name')
  public playbookName: string = undefined;

  @JsonProperty({ name: 'step_previews', cls: DeemPlaybookStep })
  public stepPreviews: DeemPlaybookStep[] = [];

  /**
   * constructor
   * @param {Partial<DeemIncident>[]} args
   */
  constructor(...args: Array<Partial<DeemIncident>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * getIntegrationEntityId
   * @returns {string}
   * @memberof DeemIncident
   */
  public getIntegrationEntityId(): string {
    return getUniqueId(this.integration, this.targetEntity);
  }

  /**
   * getRuleGroup
   * @returns {RuleGroup}
   * @memberof DeemIncident
   */
  public getRuleGroup(): RuleGroup {
    return QueryBuilder.parseRuleDefinitionTree(this.filterCondition);
  }

  /**
   * isCrossCategory
   * @type {boolean}
   * @memberof DeemIncident
   */
  public get isCrossCategory(): boolean {
    return this.getRuleGroup().isCrossCategory;
  }

  /**
   * uxScore
   * @readonly
   * @type {string}
   * @memberof DeemIncident
   */
  public get uxScore(): string {
    let uxScoreStr: string = '';
    if (!this.filter) {
      return uxScoreStr;
    }
    this.filter.split(' AND ').forEach((token: string) => {
      if (token.includes('ux_score')) {
        uxScoreStr = token.split(' = ')[1].replace(/'|'/g, '');
      }
    });
    return uxScoreStr;
  }

  /**
   * items
   * @readonly
   * @type {string[]}
   * @memberof DeemIncident
   */
  public get items(): string[] {
    const items: string[] = [];
    if (!this.filter) {
      return items;
    }
    this.filter.split(' AND ').forEach((token: string) => {
      if (token.includes('app_name')) {
        items.push(token.split(' IN ')[1].replace(/\( '|' \)/g, ''));
      }
    });
    return items;
  }

  /**
   * incidentStatusClass
   * @readonly
   * @type {string}
   * @memberof DeemIncident
   */
  public get statusClass(): string {
    return this.status.toLowerCase();
  }

  /**
   * isOpen
   * @readonly
   * @returns {boolean}
   * @memberof DeemIncident
   */
  public get isOpen(): boolean {
    return this.status === DeemIncidentStatus.OPEN;
  }

  /**
   * sourceObject
   * @readonly
   * @returns {AutomationSourceObject}
   * @memberof DeemIncident
   */
  public get sourceObject(): AutomationSourceObject {
    if (this.sourceObjectId && this.sourceObjectType) {
      return {
        sourceObjectId: this.sourceObjectId,
        sourceObjectType: this.sourceObjectType,
      };
    }
    return undefined;
  }
}
