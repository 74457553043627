/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * CustomAttributeIdentifier
 * @export
 * @class CustomAttributeIdentifier
 */
@Serializable
export class CustomAttributeIdentifier {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('raw_identifier')
  public label: string = undefined;

  /**
   * Creates an instance of CustomAttributeIdentifier.
   * @param {Array<Partial<CustomAttributeIdentifier>>} args
   * @memberof CustomAttributeIdentifier
   */
  constructor(...args: Array<Partial<CustomAttributeIdentifier>>) {
    Object.assign(this, ...args);
  }
}
