/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { IntegrationFilterStatus } from './integration-filter-status.interface';
import { OAuthPartnerTypeDisplay } from './oauth-connection.enum';
import { PartnerConnectionConfig } from './partner-connection.config';
import { TrustNetworkPartnerTypeDisplay } from './trust-network-partner-connection.enum';

/**
 * AvailablePartner
 * @export
 * @class AvailablePartner
 */
@Serializable
export class AvailablePartner {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('token')
  public token: string = undefined;

  @JsonProperty('host')
  public host: string = undefined;

  @JsonProperty('port')
  public port: number = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('username')
  public username: string = undefined;

  @JsonProperty('push_url')
  public pushUrl: string = undefined;

  @JsonProperty('api_key')
  public apiKey: string = undefined;

  @JsonProperty('client_id')
  public clientId: string = undefined;

  @JsonProperty('client_secret')
  public clientSecret: string = undefined;

  /**
   * Creates an instance of AvailablePartner
   * @param {any} args
   * @memberof AvailablePartner
   */
  constructor(...args: any) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for displayName
   *
   * @readonly
   * @type {string}
   * @memberof AvailablePartner
   */
  public get displayName(): string {
    return TrustNetworkPartnerTypeDisplay[this.name] || OAuthPartnerTypeDisplay[this.name];
  }

  /**
   * isAuthorized
   * @returns {boolean}
   * @memberof AvailablePartner
   */
  public isAuthorized(): boolean {
    return !!this.id;
  }

  /**
   * isFailedConnection
   * @returns {boolean}
   * @memberof AvailablePartner
   */
  public isFailedConnection(): boolean {
    // no connection failed info, thus always return false
    return false;
  }

  /**
   * getStatusFilter
   * @returns {IntegrationFilterStatus}
   * @memberof AvailablePartner
   */
  public getStatusFilter(): IntegrationFilterStatus {
    return { isAuthorized: this.isAuthorized(), isFailedConnection: this.isFailedConnection() };
  }

  /**
   * isAcknowledgementRequired
   * @returns {boolean}
   * @memberof AvailablePartner
   */
  public isAcknowledgementRequired(): boolean {
    return PartnerConnectionConfig.isPartnerAcknowledgeFormMap[this.name];
  }

  /**
   * isPartnerEmployeeExperience
   * @returns {boolean}
   * @memberof AvailablePartner
   */
  public isPartnerEmployeeExperience(): boolean {
    return PartnerConnectionConfig.employeeExperiencePartners.includes(this.name);
  }

  /**
   * showPartnerPermissionsAccordionRow
   * @returns {boolean}
   * @memberof AvailablePartner
   */
  public showPartnerPermissionsAccordionRow(): boolean {
    return PartnerConnectionConfig.showPartnerPermissionsAccordionRow[this.name];
  }

  /**
   * showDynamicForm
   * @readonly
   * @type {boolean}
   * @memberof AvailablePartner
   */
  public get showDynamicForm(): boolean {
    return !!PartnerConnectionConfig.partnerDynamicFieldPropertiesMap[this.name];
  }

  /**
   * partnerTags
   * @readonly
   * @type {string[]}
   * @memberof AvailablePartner
   */
  public get partnerTags(): string[] {
    return this.isPartnerEmployeeExperience() ? ['SETTINGS.EMPLOYEE_EXPERIENCE'] : ['SETTINGS.TRUST_NETWORK'];
  }
}
