/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { DeemPlaybookState } from './deem-playbook-state.enum';

/**
 * DeemPlaybookVersionDetail
 * @export
 * @class DeemPlaybookVersionDetail
 */
@Serializable
export class DeemPlaybookVersionDetail {
  @JsonProperty('playbook_version_id')
  public playbookVersionId: string = undefined;

  @JsonProperty({ name: 'playbook_version_state', customConverter: enumConverterFactory(DeemPlaybookState) })
  public playbookVersionState: DeemPlaybookState = undefined;

  @JsonProperty('version')
  public version: number = undefined;

  /**
   * Initializes instance of DeemPlaybookVersionDetail
   * @param {Array<Partial<DeemPlaybookVersionDetail>>} args
   * @memberof DeemPlaybookVersionDetail
   */
  constructor(...args: Array<Partial<DeemPlaybookVersionDetail>>) {
    Object.assign(this, ...args);
  }
}
