/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumArrayConverterFactory } from './converters';
import { ScopeSetAccessOption } from './scope-set-access-option.enum';

/**
 * RoleScopeSet
 * @export
 * @class RoleScopeSet
 */
@Serializable
export class RoleScopeSet {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('i18n_name')
  public label: string = undefined;

  @JsonProperty('i18n_description')
  public description: string = undefined;

  @JsonProperty('read_only')
  public readOnly: boolean = undefined;

  @JsonProperty({ name: 'allowed_permissions', customConverter: enumArrayConverterFactory(ScopeSetAccessOption) })
  public allowedPermissions: ScopeSetAccessOption[] = undefined;

  /**
   * Creates an instance of RoleScopeSet.
   * @param {...Array<Partial<RoleScopeSet>>} args
   * @memberof RoleScopeSet
   */
  constructor(...args: Array<Partial<RoleScopeSet>>) {
    Object.assign(this, ...args);
  }
}
