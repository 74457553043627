/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { AutomationType } from './automation-type.enum';

/**
 * AutomationType i18n key map
 * @export
 * @const {Record<AutomationType, string>}
 */
export const AUTOMATION_TYPE_KEY_MAP: Record<AutomationType, string> = {
  [AutomationType.GENERAL]: 'WORKFLOW',
  [AutomationType.QUICKFLOWS]: 'QUICKFLOW',
};
