/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';
import { each, size } from 'lodash-es';

import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { AccessType } from './common/access-type.enum';
import { defaultValueConverterFactory, enumConverterFactory } from './converters/converter-factory';
import { dateFormatConverter } from './converters/date-format.converter';
import { filterConditionConverter } from './converters/filter-condition.converter';
import { RuleGroup } from './filter';
import { GenericSearchRequest } from './generic-search-request.model';
import { ReportConfig } from './reports/report-config.model';
import { ReportDateRange } from './reports/report-date-range.model';
import { ReportFormat } from './reports/report-format.enum';
import { ReportType } from './reports/report.enum';
import { ShareView } from './share-view.model';
import { UserDetails } from './user-details.model';

/**
 * Custom Report Record.
 * @export
 * @class CustomReport
 */
@Serializable
export class CustomReport {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'report_type', customConverter: enumConverterFactory(ReportType) })
  public reportType: ReportType = undefined;

  @JsonProperty({ name: 'report_format', customConverter: enumConverterFactory(ReportFormat) })
  public reportFormat: ReportFormat = ReportFormat.CSV;

  @JsonProperty({ name: 'date_range', cls: ReportDateRange })
  public dateRange: ReportDateRange = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: filterConditionConverter, excludeToJson: true })
  public filterCondition: any = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty({ name: 'entity' })
  public entity: string = undefined;

  @JsonProperty({ name: 'entity_label', excludeToJson: true })
  public entityLabel: string = undefined;

  @JsonProperty({ name: 'join_entities_by_integration', customConverter: defaultValueConverterFactory(), excludeToJson: true })
  public joinEntitiesByIntegration: any = {};

  @JsonProperty({ name: 'total_schedules', excludeToJson: true })
  public totalSchedules: number = undefined;

  @JsonProperty({ name: 'total_downloads', excludeToJson: true })
  public totalDownloads: number = undefined;

  @JsonProperty({ name: 'total_recipients', excludeToJson: true })
  public totalRecipients: number = undefined;

  @JsonProperty({ name: 'share_view', cls: ShareView })
  public shareView: ShareView = undefined;

  @JsonProperty('column_names')
  public _columnNames: string[] = undefined;

  @JsonProperty({ name: 'shared_report', excludeToJson: true })
  public sharedReport: boolean = undefined;

  @JsonProperty({ name: 'share_count', excludeToJson: true })
  public shareCount: number = undefined;

  @JsonProperty({ name: 'owner', excludeToJson: true })
  public isOwner: boolean = false;

  @JsonProperty({ name: 'account_access_level', customConverter: enumConverterFactory(AccessType), excludeToJson: true })
  public accessLevel: AccessType = undefined;

  @JsonProperty({ name: 'starter', excludeToJson: true })
  public starter?: boolean = undefined;

  @JsonProperty({ name: 'report_config', cls: ReportConfig })
  public reportConfig?: ReportConfig = undefined;

  @JsonProperty('source_template_id')
  public sourceTemplateId: string = undefined;

  public ruleGroup: RuleGroup;

  private _ruleNames: string[] = [];
  private isAutomationSupported: boolean = false;

  /**
   * Creates an instance of CustomReport.
   * @param {Array<Partial<CustomReport>>} args
   * @memberof CustomReport
   */
  constructor(...args: Array<Partial<CustomReport>>) {
    Object.assign(this, ...args);
    // See https://github.com/euc-data/com.aw.dpa.forceofwill/pull/47#discussion_r1623473600
    // API removed join_entities_by_integration field from search API so we need to set it manually here if missing
    if (!size(this.joinEntitiesByIntegration)) {
      this.setJoinEntitiesByIntegration();
    }
  }

  /**
   * isOwnerOrHasFullAccess
   * @readonly
   * @type {boolean}
   * @memberof CustomReport
   */
  public get isOwnerOrHasFullAccess(): boolean {
    return this.isOwner || this.accessLevel === AccessType.FULL;
  }

  /**
   * Setter for isAutomationSupported
   * @readonly
   * @type {boolean}
   * @memberof CustomReport
   */
  public set supportsAutomation(isSupported: boolean) {
    this.isAutomationSupported = isSupported;
  }

  /**
   * Getter for isAutomationSupported
   * @readonly
   * @type {boolean}
   * @memberof CustomReport
   */
  public get supportsAutomation(): boolean {
    return this.isAutomationSupported;
  }

  /**
   * Getter to get unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof CustomReport
   */
  public get categoryId(): string {
    return getUniqueId(this.integration, this.entity);
  }

  /**
   * setter for columnNames
   * @param {string[]} columnNames
   * @memberof CustomReport
   */
  public set columnNames(columnNames: string[]) {
    this._columnNames = columnNames;
    this.setJoinEntitiesByIntegration();
  }

  /**
   * Getter for columnNames
   * @type {string[]}
   * @memberof CustomReport
   */
  public get columnNames(): string[] {
    return this._columnNames;
  }

  /**
   * Setter for ruleNames
   * @readonly
   * @param {string[]} ruleNames
   * @memberof CustomReport
   */
  public set ruleNames(ruleNames: string[]) {
    this._ruleNames = ruleNames;
    this.setJoinEntitiesByIntegration();
  }

  /**
   * isCrossCategoryReport
   * @returns {boolean}
   * @memberof CustomReport
   */
  public get isCrossCategoryReport(): boolean {
    const integrations = Object.keys(this.joinEntitiesByIntegration);
    return size(integrations) > 0 || size(this.joinEntitiesByIntegration[integrations[0]]) > 0;
  }

  /**
   * objectAccessLevel
   * @readonly
   * @type {AccessType}
   * @memberof CustomReport
   */
  public get objectAccessLevel(): AccessType {
    if (this.isOwner) {
      return AccessType.OWNER;
    }
    if (this.sharedReport) {
      return this.accessLevel;
    }
    // if the user is not owner and it is not shared with him then access level is Manage
    return AccessType.MANAGE;
  }

  /**
   * isPredefinedReport
   * @readonly
   * @type {boolean}
   * @memberof CustomReport
   */
  public get isPredefinedReport(): boolean {
    return this.reportType === ReportType.PREDEFINED;
  }

  /**
   * setJoinEntitiesByIntegration
   * @memberof CustomReport
   */
  private setJoinEntitiesByIntegration() {
    this.joinEntitiesByIntegration = {};
    const formJoinEntitiesByIntegration = (names: string[]) => {
      each(names, (name: string) => {
        if (name) {
          const [integration, entity] = name.split('.');
          if (this.integration === integration && this.entity === entity) {
            return;
          }
          if (!this.joinEntitiesByIntegration[integration]) {
            this.joinEntitiesByIntegration[integration] = [];
          }
          if (!this.joinEntitiesByIntegration[integration].includes(entity)) {
            this.joinEntitiesByIntegration[integration].push(entity);
          }
        }
      });
    };
    formJoinEntitiesByIntegration(this._columnNames);
    formJoinEntitiesByIntegration(this._ruleNames);
  }
}

/**
 * CustomReportSearchRequest
 * @export
 * @class CustomReportSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class CustomReportSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'report_id', excludeToJson: true })
  public reportId?: string = undefined;

  @JsonProperty('entities_by_integration')
  public entitiesByIntegration: any;

  @JsonProperty('join_entities_by_integration')
  public joinEntitiesByIntegration: any;

  /**
   * Creates an instance of CustomReportSearchRequest.
   * @param {Array<Partial<CustomReportSearchRequest>>} args
   * @memberof CustomReportSearchRequest
   */
  constructor(...args: Array<Partial<CustomReportSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * CustomReportSearchResponse
 *
 * @export
 * @class CustomReportSearchResponse
 */
export class CustomReportSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: CustomReport })
  public results: CustomReport[] = [];

  /**
   * Creates an instance of CustomReportSearchResponse.
   * @param {any} args
   * @memberof CustomReportSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
